// export const BASE_URL = "https://purium-api-dev.wsicloud.com";
export const BASE_URL = (location.hostname.search('wsicloud.net') > -1 || location.hostname.search('localhost') > -1) ? 'https://purium-api-dev.wsicloud.com' : 'https://api.puriumenrollment.com';
export const FAQ_URL = (location.hostname.search('wsicloud.net') > -1 || location.hostname.search('localhost') > -1) ? 'https://phpsharelinkapi.wsicloud.net' : 'https://api.sharepurium.com';
export const API_URL = `${BASE_URL}/api`;
export const FAQ_API_URL = `${FAQ_URL}/api`;
export const LinkTracking = {
    allow: true,
    companyName: "purium"
}
// export const IMAGE_URL = (location.hostname.search('wsicloud.net') > -1 || location.hostname.search('localhost') > -1) ? 'https://php-qickshop.wsicloud.net/assets' : 'https://media-purium-bucket.s3.us-east-1.amazonaws.com/retailsite/assets';
export const IMAGE_URL = 'https://media-purium-bucket.s3.us-east-1.amazonaws.com/retailsite/assets';
export function objectToParams(object): string {
    // Helper function to recursively clean nested objects
    function clean(obj): void {
        for (const propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            } else if (typeof obj[propName] === 'object') {
                clean(obj[propName]); // Recursively clean nested objects
            }
        }
    }

    // Clean the object
    clean(object);

    // Convert the object to a parameter string
    return Object.keys(object)
        .map((key) => {
            if (typeof object[key] === 'object') {
                return subObjectToParams(encodeURIComponent(key), object[key]);
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`;
        })
        .join('&');
}


function subObjectToParams(key, object): string {
    return Object.keys(object).map((childKey) => (typeof (object[childKey]) === 'object') ?
        subObjectToParams(`${key}[${encodeURIComponent(childKey)}]`, object[childKey]) :
        `${key}[${encodeURIComponent(childKey)}]=${encodeURIComponent(object[childKey])}`
    ).join('&');
}
var ShopifyItems = {
    "11924982485": {
        "title": "40x Aloe Vera Concentrate - 4.2 oz.",
        "id": "11924982485",
        "itemCode": "2150",
        "price": "39.95"
    },
    "11924988181": {
        "title": "Advanced Probiotic Blend - 60 ct",
        "id": "11924988181",
        "itemCode": "2640",
        "price": "31.95"
    },
    "250321534997": {
        "title": "Alkalizing Pack",
        "id": "250321534997",
        "itemCode": "12118",
        "price": "114.95"
    },
    "1337320112200": {
        "title": "Allergy Reduction Pack",
        "id": "1337320112200",
        "itemCode": "12124",
        "price": "99.95"
    },
    "51192856597": {
        "title": "Aloe Digest",
        "id": "51192856597",
        "itemCode": "2752",
        "price": "33.95"
    },
    "3554648096840": {
        "title": "Apothe-Cherry 16oz - Now Certified Organic!",
        "id": "3554648096840",
        "itemCode": "2111",
        "price": "34.95"
    },
    "11924995413": {
        "title": "BIO Regen 60 ct vegetarian capsules",
        "id": "11924995413",
        "itemCode": "14380",
        "price": "92.95"
    },
    "11924995733": {
        "title": "BIO Relax 60 ct Vegetarian Capsules",
        "id": "11924995733",
        "itemCode": "14370",
        "price": "49.95"
    },
    "6770011603016": {
        "title": "Bamboo Glass Cup  with Glass Straw - 20 oz",
        "id": "6770011603016",
        "itemCode": "9880",
        "price": "26.67"
    },
    "11924987221": {
        "title": "Barley Green Juice, Organic - 240 g",
        "id": "11924987221",
        "itemCode": "2440",
        "price": "62.95"
    },
    "23836229653": {
        "title": "Basic Pack",
        "id": "23836229653",
        "itemCode": "2520",
        "price": "49.00"
    },
    "11924982677": {
        "title": "Bee Energetic - 90 ct",
        "id": "11924982677",
        "itemCode": "2050",
        "price": "29.95"
    },
    "11924996757": {
        "title": "Bio Fruit",
        "id": "11924996757",
        "itemCode": "2491",
        "price": "52.95"
    },
    "11925004565": {
        "title": "Biome Medic",
        "id": "11925004565",
        "itemCode": "2270",
        "price": "61.95"
    },
    "11792426133": {
        "title": "Biome Medic 4 pack",
        "id": "11792426133",
        "itemCode": "2270-4",
        "price": "209.95"
    },
    "11924993621": {
        "title": "C From Nature - 270 ct",
        "id": "11924993621",
        "itemCode": "2031",
        "price": "64.95"
    },
    "11751421589": {
        "title": "CONTROL Pre-Meal Capsules - 180 ct",
        "id": "11751421589",
        "itemCode": "2310",
        "price": "45.95"
    },
    "11751439829": {
        "title": "Can't Beet This! 15-30 Servings",
        "id": "11751439829",
        "itemCode": "2750",
        "price": "57.95"
    },
    "1354571841608": {
        "title": "Can't Beet This! Terra Pouch 720 g",
        "id": "1354571841608",
        "itemCode": "2753",
        "price": "182.95"
    },
    "11924989973": {
        "title": "Carrot Juice Plus - 225 g",
        "id": "11924989973",
        "itemCode": "2470",
        "price": "39.95"
    },
    "6616015765576": {
        "title": "Chai Protein - 15 Servings",
        "id": "6616015765576",
        "itemCode": "2780",
        "price": "49.95"
    },
    "4039473987656": {
        "title": "Coco Hydrate - Pineapple",
        "id": "4039473987656",
        "itemCode": "2754",
        "price": "34.95"
    },
    "11925000917": {
        "title": "Cocoa Mint Spirulina - 30 Serving",
        "id": "11925000917",
        "itemCode": "2438",
        "price": "35.95"
    },
    "6616015831112": {
        "title": "Coffee Protein - 15 Servings",
        "id": "6616015831112",
        "itemCode": "2781",
        "price": "49.95"
    },
    "6616015863880": {
        "title": "Coffee Shop Proteins - Variety Pack (1 of each Chai, Coffee, Matcha)",
        "id": "6616015863880",
        "itemCode": "2783",
        "price": "139.95"
    },
    "6652143272008": {
        "title": "Collagen Support Pack",
        "id": "6652143272008",
        "itemCode": "12142",
        "price": "139.95"
    },
    "105647734805": {
        "title": "Core 4 Athletes",
        "id": "105647734805",
        "itemCode": "11147",
        "price": "230.00"
    },
    "6615123361864": {
        "title": "Cracked Cell Chlorella Powder, Organic - 8 oz",
        "id": "6615123361864",
        "itemCode": "2484",
        "price": "42.95"
    },
    "6593845887048": {
        "title": "Cracked Cell Chlorella Tablets, Organic  480ct (250mg)",
        "id": "6593845887048",
        "itemCode": "2483",
        "price": "35.95"
    },
    "4493756923976": {
        "title": "Daily Core 4",
        "id": "4493756923976",
        "itemCode": "11154",
        "price": "255.00"
    },
    "6544672555080": {
        "title": "Daily Core 4 + Immune",
        "id": "6544672555080",
        "itemCode": "12520",
        "price": "305.00"
    },
    "6726244859976": {
        "title": "Daily Core 4 + Immune - Customizable",
        "id": "6726244859976",
        "itemCode": "2520",
        "price": "310.00"
    },
    "5010964611144": {
        "title": "Daily Core 4 - Customizable",
        "id": "5010964611144",
        "itemCode": "11153",
        "price": "260.00"
    },
    "3986523488328": {
        "title": "Daily Core 4 Nutrition + Performance",
        "id": "3986523488328",
        "itemCode": "11152",
        "price": "485.00"
    },
    "5850725580872": {
        "title": "Daily Core 4 w/ Epi-Genius Family",
        "id": "5850725580872",
        "itemCode": "14600",
        "price": "255.00"
    },
    "4523134648392": {
        "title": "Daily Core 4 w/ Power Shake AND L.O.V.",
        "id": "4523134648392",
        "itemCode": "11155",
        "price": "258.00"
    },
    "1354572922952": {
        "title": "Daily Fiber Organic Terra Pouch - Caramel Apple",
        "id": "1354572922952",
        "itemCode": "2016",
        "price": "36.95"
    },
    "5868482265160": {
        "title": "Daily Fiber Organic Terra Pouch - Original",
        "id": "5868482265160",
        "itemCode": "2017",
        "price": "36.95"
    },
    "6652143140936": {
        "title": "Dark Berry Protein - 2 Terra Pouches (30 servings)",
        "id": "6652143140936",
        "itemCode": "2761",
        "price": "109.95"
    },
    "6730440081480": {
        "title": "Dark Berry Protein Travel / Sample Packs (5 pack of 5-serving pouches)",
        "id": "6730440081480",
        "itemCode": "2763-5",
        "price": "109.95"
    },
    "23829512213": {
        "title": "Digital Launch Pack",
        "id": "23829512213",
        "itemCode": "2520",
        "price": "199.00"
    },
    "11751423445": {
        "title": "Enzyme Advantage - 90 ct",
        "id": "11751423445",
        "itemCode": "2610",
        "price": "35.95"
    },
    "6774336454728": {
        "title": "Epi-Genius Cats",
        "id": "6774336454728",
        "itemCode": "4850",
        "price": "52.95"
    },
    "3922404900936": {
        "title": "Epi-Genius Dogs",
        "id": "3922404900936",
        "itemCode": "4840",
        "price": "52.95"
    },
    "3922404769864": {
        "title": "Epi-Genius Family",
        "id": "3922404769864",
        "itemCode": "4600",
        "price": "109.95"
    },
    "5860847255624": {
        "title": "Family Nutrition Pack",
        "id": "5860847255624",
        "itemCode": "12135",
        "price": "450.00"
    },
    "6621657235528": {
        "title": "Fruit & Veggie+ 3-Pack - Organic",
        "id": "6621657235528",
        "itemCode": "12138",
        "price": "368.50"
    },
    "6640982163528": {
        "title": "Fruit & Veggie+ 3-Pack - Organic - Customizable",
        "id": "6640982163528",
        "itemCode": "12141",
        "price": "382.50"
    },
    "6621657202760": {
        "title": "Fruit & Veggie+ Pack - Organic",
        "id": "6621657202760",
        "itemCode": "12137",
        "price": "124.50"
    },
    "6640982130760": {
        "title": "Fruit & Veggie+ Pack - Organic - Customizable",
        "id": "6640982130760",
        "itemCode": "12139",
        "price": "129.50"
    },
    "11924992725": {
        "title": "Fulvic Zeolite – 1 oz",
        "id": "11924992725",
        "itemCode": "2125",
        "price": "89.95"
    },
    "6576177086536": {
        "title": "Grain-Free Pack",
        "id": "6576177086536",
        "itemCode": "12136",
        "price": "522.00"
    },
    "11924999061": {
        "title": "Green Spectrum Lemon - 60 Serving",
        "id": "11924999061",
        "itemCode": "2401",
        "price": "43.95"
    },
    "11924999637": {
        "title": "Green Spectrum Original - 60 Serving",
        "id": "11924999637",
        "itemCode": "2402",
        "price": "43.95"
    },
    "136936816661": {
        "title": "Gut Health Pack",
        "id": "136936816661",
        "itemCode": "12116",
        "price": "129.95"
    },
    "4497457414216": {
        "title": "Healthy Immune Function Pack",
        "id": "4497457414216",
        "itemCode": "12133",
        "price": "145.00"
    },
    "1318639599688": {
        "title": "Healthy Inflammatory Response Pack",
        "id": "1318639599688",
        "itemCode": "12122",
        "price": "114.95"
    },
    "11924984213": {
        "title": "Heart Aid - 180 ct",
        "id": "11924984213",
        "itemCode": "4030",
        "price": "42.95"
    },
    "5850725744712": {
        "title": "Holistic Fitness Pack",
        "id": "5850725744712",
        "itemCode": "11215",
        "price": "480.00"
    },
    "5850735706184": {
        "title": "Holistic Fitness Pack - Customizable",
        "id": "5850735706184",
        "itemCode": "11216",
        "price": "485.00"
    },
    "11924992469": {
        "title": "Ionic Elements 4 oz. liquid",
        "id": "11924992469",
        "itemCode": "2120",
        "price": "45.95"
    },
    "11924985109": {
        "title": "Joint-Flex - 180 ct",
        "id": "11924985109",
        "itemCode": "4020",
        "price": "55.95"
    },
    "124023996437": {
        "title": "Juice Bar in a Bag",
        "id": "124023996437",
        "itemCode": "12111",
        "price": "260.00"
    },
    "11924989397": {
        "title": "Kamut Blend - 90 g",
        "id": "11924989397",
        "itemCode": "2420",
        "price": "33.95"
    },
    "1354572955720": {
        "title": "Kamut Blend Terra Pouch - Family Size - 270 g",
        "id": "1354572955720",
        "itemCode": "2422",
        "price": "82.95"
    },
    "11751435605": {
        "title": "Kids - Immune Shield",
        "id": "11751435605",
        "itemCode": "2235",
        "price": "41.95"
    },
    "11751435349": {
        "title": "Kids - In Focus",
        "id": "11751435349",
        "itemCode": "2234",
        "price": "41.95"
    },
    "11751435861": {
        "title": "MVP Family Chocolate",
        "id": "11751435861",
        "itemCode": "2236",
        "price": "72.95"
    },
    "1354573250632": {
        "title": "MVP Sport Terra Pouch Chocolate 15-30 Servings",
        "id": "1354573250632",
        "itemCode": "2743",
        "price": "84.95"
    },
    "1354573217864": {
        "title": "MVP Sport Terra Pouch Vanilla 15-30 Servings",
        "id": "1354573217864",
        "itemCode": "2742",
        "price": "84.95"
    },
    "6616016453704": {
        "title": "Matcha Protein - 15 Servings",
        "id": "6616016453704",
        "itemCode": "2782",
        "price": "49.95"
    },
    "11924983381": {
        "title": "Men's Defense - 60 ct",
        "id": "11924983381",
        "itemCode": "3510",
        "price": "39.95"
    },
    "11924992021": {
        "title": "More Greens - 240 g",
        "id": "11924992021",
        "itemCode": "2410",
        "price": "48.95"
    },
    "11924991701": {
        "title": "Organic Spirulina Powder",
        "id": "11924991701",
        "itemCode": "2432",
        "price": "49.95"
    },
    "6774353330248": {
        "title": "Portable Electric Blender 18 oz  - Pink - Limited Edition Color (While Supplies Last)",
        "id": "6774353330248",
        "itemCode": "9833",
        "price": "35.95"
    },
    "6762121363528": {
        "title": "Portable Electric Blender 18 oz - Blue - Limited Edition Color (While Supplies Last)",
        "id": "6762121363528",
        "itemCode": "2520",
        "price": "35.95"
    },
    "6652055814216": {
        "title": "Portable Electric Blender 18 oz - Red - Limited Edition Color (While Supplies Last)",
        "id": "6652055814216",
        "itemCode": "9730",
        "price": "35.95"
    },
    "11751448277": {
        "title": "Power Shake Apple Berry - 2 Terra Pouches (30 servings)",
        "id": "11751448277",
        "itemCode": "2001",
        "price": "109.95"
    },
    "6730478420040": {
        "title": "Power Shake Apple Berry Travel / Sample Packs (5 pack of 5-serving pouches)",
        "id": "6730478420040",
        "itemCode": "2008-5",
        "price": "109.95"
    },
    "6615339204680": {
        "title": "Power Shake Flavor Combo - 1 Apple Berry (15 servings) and 1 Unflavored (15 servings)",
        "id": "6615339204680",
        "itemCode": "2006",
        "price": "109.95"
    },
    "4425016410184": {
        "title": "Power Shake Unflavored - 2 Terra Pouches (30 servings)",
        "id": "4425016410184",
        "itemCode": "2005",
        "price": "109.95"
    },
    "6642305138760": {
        "title": "Pumpkin Spice Protein 15 servings (while supplies last)",
        "id": "6642305138760",
        "itemCode": "2744",
        "price": "49.95"
    },
    "6610265571400": {
        "title": "Purium Better Together Blank Greeting Card - 20 Pack",
        "id": "6610265571400",
        "itemCode": "2520",
        "price": "20.00"
    },
    "6610265505864": {
        "title": "Purium Congratulations Blank Greeting Card - 20 Pack",
        "id": "6610265505864",
        "itemCode": "2520",
        "price": "20.00"
    },
    "6610265636936": {
        "title": "Purium Leaves Blank Greeting Card - 20 Pack",
        "id": "6610265636936",
        "itemCode": "2520",
        "price": "20.00"
    },
    "6772577435720": {
        "title": "Purium Organic Fleece Zip Hoodie – Shadow",
        "id": "6772577435720",
        "itemCode": "9881",
        "price": "66.66"
    },
    "6622799200328": {
        "title": "Purium Picnic Blanket - Green",
        "id": "6622799200328",
        "itemCode": "2520",
        "price": "19.95"
    },
    "6610265604168": {
        "title": "Purium White Blank Greeting Card - 20 Pack",
        "id": "6610265604168",
        "itemCode": "2520",
        "price": "20.00"
    },
    "11924987605": {
        "title": "RENEW Hair, Skin, and Nails - 180 ct",
        "id": "11924987605",
        "itemCode": "3020",
        "price": "36.95"
    },
    "11924986581": {
        "title": "Revive-It-All - 90 ct",
        "id": "11924986581",
        "itemCode": "4010",
        "price": "56.95"
    },
    "1354572988488": {
        "title": "Rice Bran Solubles Terra Pouch - 360 g",
        "id": "1354572988488",
        "itemCode": "2456",
        "price": "49.95"
    },
    "6685219586120": {
        "title": "Signature Collagen Support Pack",
        "id": "6685219586120",
        "itemCode": "11237",
        "price": "429.00"
    },
    "11924997141": {
        "title": "Spirulina Capsules 180 ct",
        "id": "11924997141",
        "itemCode": "2433",
        "price": "29.95"
    },
    "4449011073096": {
        "title": "Sports Power Pack",
        "id": "4449011073096",
        "itemCode": "12130",
        "price": "125.00"
    },
    "4449011138632": {
        "title": "Sports Pro Pack",
        "id": "4449011138632",
        "itemCode": "12131",
        "price": "300.00"
    },
    "5860847222856": {
        "title": "Stainless Steel Shaker Bottle",
        "id": "5860847222856",
        "itemCode": "9827",
        "price": "24.99"
    },
    "6612722024520": {
        "title": "Stainless Steel Water Bottle - 24 oz",
        "id": "6612722024520",
        "itemCode": "9828",
        "price": "20.00"
    },
    "11751429269": {
        "title": "Super Amino 23 - 150 ct",
        "id": "11751429269",
        "itemCode": "3551",
        "price": "75.00"
    },
    "11901734101": {
        "title": "Super Amino 23 - 6 pack",
        "id": "11901734101",
        "itemCode": "3551-6",
        "price": "399.00"
    },
    "6723376119880": {
        "title": "Super CleansR - 80 ct",
        "id": "6723376119880",
        "itemCode": "2261",
        "price": "72.95"
    },
    "11751433557": {
        "title": "Super Life Formula - 90 ct",
        "id": "11751433557",
        "itemCode": "3525",
        "price": "75.95"
    },
    "11751432853": {
        "title": "Super Lytes",
        "id": "11751432853",
        "itemCode": "2060",
        "price": "32.95"
    },
    "6615339434056": {
        "title": "Super Meal L.O.V. Flavor Combo - 1 Original (15 servings) and 1 Vanilla Chai (15 servings)",
        "id": "6615339434056",
        "itemCode": "2714",
        "price": "121.95"
    },
    "1354573119560": {
        "title": "Super Meal L.O.V. Original - 2 Terra Pouches (30 servings)",
        "id": "1354573119560",
        "itemCode": "2713",
        "price": "121.95"
    },
    "1354573152328": {
        "title": "Super Meal L.O.V. Vanilla Chai - 2 Terra Pouches (30 servings)",
        "id": "1354573152328",
        "itemCode": "2912",
        "price": "121.95"
    },
    "11751433877": {
        "title": "Super Xanthin",
        "id": "11751433877",
        "itemCode": "3537",
        "price": "33.95"
    },
    "105653370901": {
        "title": "Ten-Day Cleansing",
        "id": "105653370901",
        "itemCode": "11159",
        "price": "300.00"
    },
    "1384026046536": {
        "title": "The Green Foods Bible by David Sandoval",
        "id": "1384026046536",
        "itemCode": "8410",
        "price": "18.65"
    },
    "4422200623176": {
        "title": "Ultimate Lifestyle Transformation",
        "id": "4422200623176",
        "itemCode": "11200",
        "price": "480.00"
    },
    "6679510351944": {
        "title": "Ultimate Lifestyle Transformation + Collagen Support",
        "id": "6679510351944",
        "itemCode": "11235",
        "price": "479.00"
    },
    "6544672587848": {
        "title": "Ultimate Lifestyle Transformation + Immune",
        "id": "6544672587848",
        "itemCode": "11208",
        "price": "530.00"
    },
    "6544672620616": {
        "title": "Ultimate Lifestyle Transformation + Immune - Customizable",
        "id": "6544672620616",
        "itemCode": "11209",
        "price": "545.00"
    },
    "6568995258440": {
        "title": "Ultimate Lifestyle Transformation + Performance",
        "id": "6568995258440",
        "itemCode": "11210",
        "price": "750.00"
    },
    "6568995291208": {
        "title": "Ultimate Lifestyle Transformation + Performance - Customizable",
        "id": "6568995291208",
        "itemCode": "11220",
        "price": "765.00"
    },
    "6685219520584": {
        "title": "Ultimate Lifestyle Transformation + Signature Collagen Support",
        "id": "6685219520584",
        "itemCode": "11236",
        "price": "699.00"
    },
    "4454971015240": {
        "title": "Ultimate Lifestyle Transformation - Customizable",
        "id": "4454971015240",
        "itemCode": "11205",
        "price": "495.00"
    },
    "5869229604936": {
        "title": "Ultimate Lifestyle Transformation - EpiGenius Family",
        "id": "5869229604936",
        "itemCode": "11207",
        "price": "495.00"
    },
    "11924989077": {
        "title": "Vir-U-Sure",
        "id": "11924989077",
        "itemCode": "2510",
        "price": "39.95"
    },
    "11751430805": {
        "title": "White American Ginseng 8 oz. Extract",
        "id": "11751430805",
        "itemCode": "2130",
        "price": "52.95"
    },
    "11924983701": {
        "title": "Women's Defense - 60 ct",
        "id": "11924983701",
        "itemCode": "3010",
        "price": "44.95"
    },
    "6544672489544": {
        "title": "ZinC-ADE - 90 ct",
        "id": "6544672489544",
        "itemCode": "2520",
        "price": "52.95"
    }
};

export function getItemCodeByShopifyId(itemcode): string {
    return ShopifyItems[itemcode].itemCode;
}

export const SuperAminoLimitItem = {
    name: "Super Amino 23 - 150 ct - Buy 3 Get 1 Free (Limit 6) Promotion Ends May 28 & While Supplies Last",
    itemCode: "3551-6"
  };





