<app-header></app-header>
<div id="collection-page" class="page-wrapper subscribe custom-calss">
    <div class="ult-checkout-section wf-section">
        <div class="collection-padding">
            <h1 class="checkout-h1 smart-order-h1-choose" [ngStyle]="{'text-transform': (PageTitle=='IRT - responsibletechnology.org') ? 'math-auto' :'capitalize'}">{{PageTitle ? PageTitle : _router.url.includes("Collection9") ? 'Convention 2025' : 'Category Name'}}</h1>
            <div class="banner-img">
                <img *ngIf="_router.url.includes('Collection9')" src="assets/images/Banner/ConventionBanner3.png" class="mt-4 banner-inner-img" alt="" width="100%" height="auto">
            </div>
            <br>
            <div class="w-layout-grid enhance-grid cen m-b-30" *ngIf="_enrollSession.mobileViewMethod() && productList.length > 0">
                <div *ngFor="let topPick of productList" class="m-b-30">
                    <div class="itemBorder" (click)="productInfo(topPick)">
                        <img src="{{topPick.SmallPicture}}" loading="lazy" class="checkout-product-img checkout-product-img-grid cursor-pointer">
                    </div>
                    <div class="" (click)="productInfo(topPick)">
                        <div class="checkout-h2 enhance-grid-h2">
                            {{topPick.Description}}
                        </div>
                    </div>
                    <div class="enhance-grid-price mh-22" *ngIf="!_auth.isLoggedIn()">Loyal Customer : ${{topPick.Price |number : '1.2-2'}} USD</div>
                    <div class="enhance-grid-price mh-22" *ngIf="_auth.isLoggedIn()">
                        <div *ngFor="let itemprices of topPick.ItemPrices">
                          <span *ngIf="itemprices.PriceTypeID == _enrollSession.data.priceType">Your Price : ${{itemprices.Price |number : '1.2-2'}} USD</span>
                        </div>
                      </div>
                    <div class="enhance-grid-price mh-22 r-b" *ngIf="!_auth.isLoggedIn()">Retail : ${{topPick.Other1Price |number : '1.2-2'}} USD</div>
                    <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn()"><del>Retail : ${{topPick.Other1Price |number : '1.2-2'}} USD</del></div>
                    <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{topPick.ShortDetail3}}</div>
                    <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59" class="button enhance-grid-button w-button hover-white" (click)="addToCart(topPick,1,2)">Add to Cart</a>
                </div>
            </div>
            <div class="w-layout-grid enhance-grid" [ngClass]="{'width-2-item': productList.length === 2}" *ngIf="!_enrollSession.mobileViewMethod() && productList.length > 0">
                <div *ngFor="let topPick of productList" class="m-b-30">
                    <div class="itemBorder" (click)="productInfo(topPick)">
                        <img src="{{topPick.SmallPicture}}" loading="lazy" class="checkout-product-img checkout-product-img-grid cursor-pointer">
                    </div>
                    <div class="" (click)="productInfo(topPick)">
                        <div class="checkout-h2 enhance-grid-h2">
                            {{topPick.Description}}
                        </div>
                    </div>
                    <div class="enhance-grid-price mh-22" *ngIf="!_auth.isLoggedIn()">Loyal Customer : ${{topPick.Price |number : '1.2-2'}} USD</div>
                    <div class="enhance-grid-price mh-22" *ngIf="_auth.isLoggedIn()">
                        <div *ngFor="let itemprices of topPick.ItemPrices">
                          <span *ngIf="itemprices.PriceTypeID == _enrollSession.data.priceType">Your Price : ${{itemprices.Price |number : '1.2-2'}} USD</span>
                        </div>
                      </div>
                    <div class="enhance-grid-price mh-22 r-b" *ngIf="!_auth.isLoggedIn()">Retail : ${{topPick.Other1Price |number : '1.2-2'}} USD</div>
                    <div class="enhance-grid-price mh-22 r-b" *ngIf="_auth.isLoggedIn()"><del>Retail : ${{topPick.Other1Price |number : '1.2-2'}} USD</del></div>
                    <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{topPick.ShortDetail3}}</div>
                    <a class="button enhance-grid-button w-button hover-white" (click)="addToCart(topPick,1,2,sideNav);">Add to Cart</a>
                </div>
            </div>
            <div *ngIf="productList.length == 0">
                <h1 class="checkout-h1 smart-order-h1-choose">No products to display!</h1>
            </div>
            <div class="banner-img m-b-30">
                <img *ngIf="_router.url.includes('Collection9')" src="assets/images/Banner/ConventionBanner4.png" class="mt-4 banner-inner-img" alt="" width="100%" height="auto">
            </div>
            <app-footercomponent></app-footercomponent>
        </div>
    </div>
</div>