import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { RestService } from 'app/shared/rest.service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderSubItemComponent } from 'app/shared/Orders-Sub-Item/Orders-Sub-Item.component';
import { MatSidenav } from '@angular/material/sidenav';
import { ShipPopupDialogComponent } from './shipPopUp-dialog/ship-popup-dialog.component';
import { SubscriptionInfoPopupDialogComponent } from './SubscriptionInfo-dialog/subscriptionInfo-popup-dialog.component';
import { SubscriptionDatePopupDialogComponent } from './SubscriptionDate-dialog/subscriptionDate-popup-dialog.component';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { SubscribeDialogComponent } from 'app/shared/subscribe-dialog/subscribe-dialog.component';
import { IMAGE_URL } from 'app/constants';

@Component({
    selector: 'Subscribe',
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    templateUrl: './Subscribe.component.html',
    styleUrls: ['./Subscribe.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SubscribeComponent implements OnInit {
    IMAGE_URL = IMAGE_URL;
    productList: any[] = [];
    topPicks: any[] = [];
    alternates: any[] = [];
    freeProducts: any[] = [];
    smarOrderallProductList: any[] = [];
    routPath: any = "Shipping";
    onSearch:  any;
    panelOpenState : any;
    siteType: any;
    isDesktopView: boolean;
    SubscriptionProcessType: any;
    dialogRef: any;
    AutoOrderProcessTy: FormControl =  new FormControl('AutoOrderProcessTy1');
    smartOrderDateForLifestyle: any;
    currentRouterPath: any;
    ProductCode: any;
    constructor(
        private _toastr: ToastrService,
        private _restService: RestService,
        public _sharedMethodService: SharedMethodService,
        public _enrollSession: EnrollSessionService,
        private _matDialog: MatDialog,
        private router : Router,
        private _activateRoute: ActivatedRoute,
    ) {
        this.siteType = sessionStorage.getItem("siteType");
        this.ProductCode = this._activateRoute.snapshot.paramMap.get('id');
        this.currentRouterPath = this.router.url.split('?')[0];
        if(((this._enrollSession.data.RetailOrderSummary == undefined) || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.Enrollpack && this._enrollSession.data.RetailOrderSummary.Enrollpack == undefined && this._enrollSession.data.RetailOrderSummary.Enrollpack.length == 0)) &&  (this.siteType && this.siteType!=null)){
            if(this._enrollSession.data.RetailOrderSummary.Enrollpack.length == 0) {
                this._toastr.warning("Please select Transformation Pack", '');
            }
            this.router.navigate(['/AllProducts']);
        }
        this.smartOrderDateForLifestyle = moment().add(1, 'months').format('MM/DD/YYYY');
     }

    ngOnInit(): void {
        this._sharedMethodService.headerSearchValue = ''
        this.getAllProduct();
        this.getSmartOrderAllProduct();
        this._sharedMethodService.isOpen = true;
        this.SubscriptionProcessType = localStorage.getItem('SubscriptionProcessType') || "AutoOrderProcessTy1";
        localStorage.setItem("SubscriptionProcessType",this.SubscriptionProcessType);
        sessionStorage.setItem('onUltLifestyleFlow', 'false');
        if(window.innerWidth > 550){
            this.isDesktopView = true;
        }
        else{
            this.isDesktopView =  false;
        }
    }
    getAllProduct() {
        const request = {
            'EnrollmentType': '',
            'CustomerType': '',
            'WebCategoryId': 466,
            'isUpgradeCustomer': false,
            'IsSubscriptionOrder': true,
            'PriceType': ''
        };
        this._restService.getProducts(request).subscribe((res: any) => {
            if (res) {
                const TopPick = [];
                const Alternate = [];
                const FreeProducts = [];
                this.productList = res.Items;
                this.productList.forEach((item) => {
                    if (item.CategoryID === 473) {
                        TopPick.push(item);
                    } else if (item.CategoryID === 474) {
                        Alternate.push(item);
                    } else if (item.CategoryID === 475) {
                        FreeProducts.push(item);
                    }
                });
                this.topPicks = TopPick;
                this.alternates = Alternate;
                this.freeProducts = FreeProducts;
            } else {
                this._toastr.error('Error');
            }
        }, error => {
            this._toastr.error(error.error.Message, 'Error');
        });
    }
    getSmartOrderAllProduct() {
        if(sessionStorage.getItem("AllProducts472") !== null && sessionStorage.getItem("AllProducts472") !== undefined){
            this.smarOrderallProductList = JSON.parse(sessionStorage.getItem("AllProducts472"));
        }
        else{
            const request = {
                'EnrollmentType': '',
                'CustomerType': '',
                'WebCategoryId': 472,
                'isUpgradeCustomer': false,
                'IsSubscriptionOrder': true,
                'PriceType': ''
            };
            this._restService.getProducts(request).subscribe((res: any) => {
                if (res) {
                     this.smarOrderallProductList = res.Items;
                } else {
                    this._toastr.error('Error');
                }
            }, error => {
                this._toastr.error(error.error.Message, 'Error');
            });
        }
    }
    productInfo(item) {
        sessionStorage.setItem('productDisplay', JSON.stringify(item))
        this.router.navigate(['/Subscribe', parseInt(item.ItemCode)])
    }
    onSearchChange(event) {
        this.onSearch = event.target.value;
    }

    addToCart(item: any, quantity: any, type: any, sideNav: MatSidenav){
        if (item.KitMembers && item.KitMembers.length > 0) {
            const dialogRef = this._matDialog.open(OrderSubItemComponent, {
                width: '600px',
                panelClass: ['order-sub-item', 'sub-item-modal'],
                data: {
                    item: item,
                    type:type,
                    
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result.event == 'submit') {
                    this._sharedMethodService.toggleSidenavforaddtocart(sideNav);
                }
            });
        }
        else{
            this._sharedMethodService.ContinueRetailOrder(item, quantity, type);
            this._sharedMethodService.toggleSidenavforaddtocart(sideNav);
        }
    }
    getHeaderSearchedValue(){
        return this._sharedMethodService.headerSearchValue;
    }
    processTypeChange(value) {
        localStorage.setItem("SubscriptionProcessType",value);
        if(value === 'AutoOrderProcessTy1'){
            this._sharedMethodService.processType = 'Always Ship';
        }
        else if(value === 'AutoOrderProcessTy2'){
            this._sharedMethodService.processType = 'Backup Order';
        }
    }
    OpenAlwaysShipPopup(id){
        this.dialogRef = this._matDialog.open(ShipPopupDialogComponent, {
            width: '429px',
            maxWidth: '429px',
            height: '289px',
            panelClass: '',
            data:id,
            disableClose: true
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {

            });
    }
    SubscriptionInfoPopup(){
        this.dialogRef = this._matDialog.open(SubscriptionInfoPopupDialogComponent, {
            width: '80%',
            maxWidth: '80%',
            height: '250px',
            panelClass: '',
            disableClose: true
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {

            });
    }
    closeMobileSummaryCart() {
        this._enrollSession.SummaryCartMobOnly = !this._enrollSession.SummaryCartMobOnly;
    }
    SubscriptionDatePopup(){
        this.dialogRef = this._matDialog.open(SubscriptionDatePopupDialogComponent, {
            width: '80%',
            maxWidth: '80%',
            height: '250px',
            panelClass: '',
            disableClose: true
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {

            });
    }
    subscribeDialog() {
        if ((this.currentRouterPath == "/Subscribe" || this.currentRouterPath == "/Subscribe/" + this.ProductCode) && this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0) {
          if (window.innerWidth < 550) {
            this.closeMobileSummaryCart();
          }
          else {
            this._sharedMethodService.toggleSidenavforaddtocart();
          }
          this.onClick();
        }
        else if (this.currentRouterPath == "/Subscribe" || this.currentRouterPath == "/Subscribe/" + this.ProductCode) {
          this.dialogRef = this._matDialog.open(SubscribeDialogComponent, {
            width: '500px',
            maxWidth: '500px',
            height: '375px',
            panelClass: '',
            disableClose: true
          });
          this.dialogRef.afterClosed()
            .subscribe(response => {
              if (!response) {
                return;
              } else {
                if (response == 'false') {
                  if (window.innerWidth < 550) {
                    this.closeMobileSummaryCart();
                  }
                  else {
                    this._sharedMethodService.toggleSidenavforaddtocart();
                  }
                  this.onClick();
                  this.router.navigate(['/Shipping']);
                }
                if (response == 'true') {
                  return
                }
              }
    
            });
        }
      }
      onClick() {
        if (this.currentRouterPath.includes("Subscribe") && this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 && (this._enrollSession.data.smartOrderDate == undefined || this._enrollSession.data.smartOrderDate == null || this._enrollSession.data.smartOrderDate == '')) {
          this._toastr.error('Please select Smart Order Date', 'error');
          const firstElementWithError = document.querySelector('.dateValidation') as HTMLElement;
          if (firstElementWithError) {
            const scrollPositionAbove = firstElementWithError.offsetTop - 100; // Adjust the value as needed
            window.scrollTo({ top: scrollPositionAbove, behavior: 'smooth' });
          }
          return;
        }
        else
          if (this.currentRouterPath.includes("Subscribe")) {
            this.router.navigate(['/Shipping']);
          }
      }
}



