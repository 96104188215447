<!-- ======================= PAGE CONTENT ====================================== -->

<app-header></app-header>
<div id="support-page" fxLayout="column">
    <div class="container mb-5" fxLayout="column" fxLayoutAlign="space-evenly center" fxFlex="80"
        style="width:100%;">
        <div style="background-color:#00613E; color: white;width: 100%;">
            <img class="img-fluid" [src]="IMAGE_URL+'/img/Support Banner-1.png'" style="width:inherit;" />
        </div>
        <!-- Support Form -->
        <div fxLayout="column" fxLayoutAlign="space-evenly center" class="container pt-2 mt-2 text-center"
            style="color:#00613E;width: 100%;">
            <h1>SUPPORT FORMS</h1>
            <div class="row justify-content-center mt-5">
                <!-- 1 -->
                <div class="card m-2" style="width: 17rem;">
                    <img class="card-img-top pt-2"
                        src="https://www.puriumcorporate.com/puriumMedia/wp-content/uploads/2023/03/1s.png"
                        alt="Missing or Damaged Products">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Missing or Damaged Products</h5>
                        <p class="card-text">We take great pride in our work and sometimes mistakes happen.
                            Purium is committed to make it right.</p>
                        <a href="https://pure-premium.typeform.com/to/yAWYqnWE" target="_blank" class="btn mt-auto"
                            style="background-color: #006145; color: white; border-radius: 0;">COMPLETE THIS FORM</a>
                    </div>
                </div>
                <!-- 2 -->
                <div class="card m-2" style="width: 17rem;">
                    <img class="card-img-top pt-2"
                        src="https://www.puriumcorporate.com/puriumMedia/wp-content/uploads/2023/03/4s.png"
                        alt="Account Maintenance">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Primary Support</h5>
                        <p class="card-text">Do you have questions about your order, Monthly Subscription, or account? Use our
                            Customer Care form for account maintenance, such as editing, cancelling orders, and
                            upgrading.
                        </p>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSe9O1GnjPbLUdPQTFk-_aGx66-5Nu1smNICOTgtzFM1Fw3Eqg/viewform"
                            target="_blank" class="btn mt-auto"
                            style="background-color: #006145; color: white; border-radius: 0;">COMPLETE THIS FORM</a>
                    </div>
                </div>
                <!-- 4 -->
                <div class="card m-2" style="width: 17rem;">
                    <img class="card-img-top pt-2"
                        src="https://www.puriumcorporate.com/puriumMedia/wp-content/uploads/2023/03/2s.png"
                        alt="Special Attachments">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Special Attachments</h5>
                        <p class="card-text">Do you have documentation to submit to Purium? Upload (max of 2) for tax
                            ID,
                            name change, business formation documents, etc., using the above form.</p>
                        <a href="https://pure-premium.typeform.com/to/sAYr0zb3" target="_blank" class="btn mt-auto"
                            style="background-color: #006145; color: white; border-radius: 0;">COMPLETE THIS FORM</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container text-center pt-5 pb-3">
            <h1>Customer Care</h1>
            <br>
            <h5>Monday - Friday <br> 7:00 AM - 5:00 PM PT</h5>
            <p>We are experiencing a heavy volume of inquiries<br> and thank you for your patience.</p>
            <p>Please submit <strong>1 Web Form</strong> or <strong>Phone Call</strong> per issue and<br>share as many
                details as possible for the quickest resolution.
            </p>
            <p>
                888-PHP-ORDER (888-747-6733)
            </p>
            <br>
        </div>
        <div class="container text-center pt-5 pb-3">
            <h1>QUICK SUPPORT</h1>
            <br>
            <mat-accordion>
                <!-- <mat-expansion-panel class="border-txt" *ngFor="let faqItem of faqItems; let i = index;">
                    <mat-expansion-panel-header class="header-border">
                        {{ faqItem.question }}
                    </mat-expansion-panel-header>
                    <p style="text-align: left !important;" [innerHTML]="faqItem.answer"></p>
                </mat-expansion-panel> -->
                <mat-expansion-panel class="border-txt">
                    <mat-expansion-panel-header class="header-border">
                        Placing an Order
                    </mat-expansion-panel-header>
                    <p style="text-align: left !important;">
                        Go to <a href="https://ishoppurium.com/">ishoppurium.com</a> and login to your account. If you
                        cannot remember your password use the “Reset Password” function to have a password link sent to
                        you.
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel class="border-txt">
                    <mat-expansion-panel-header class="header-border">
                        Cancel an Existing Order
                    </mat-expansion-panel-header>
                    <p style="text-align: left !important;">
                        If you need to modify your order, sign in at <a
                            href="https://ishoppurium.com/">ishoppurium.com</a> and go to the "My Account" tab to
                        display order history. <br><br>The EDIT option becomes available approximately 20 minutes after
                        completing your order. Act quickly as it goes away once the order begins processing.
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel class="border-txt">
                    <mat-expansion-panel-header class="header-border">
                        Track Your Order
                    </mat-expansion-panel-header>
                    <p style="text-align: left !important;">
                        Log into <a href="https://ishoppurium.com/">ishoppurium.com</a>. The “My Account” tab displays
                        order history, including tracking number. Your tracking number will also be emailed to you once
                        your order has been shipped.<br><br>FedEx Delivery Manager App<br><br>Most of Purium's orders
                        are sent via FedEx. Download their Delivery Manager App to use these great features:<br><br>
                        Get tracking updates (via text message)<br>
                        Request package be held at “hold facility”<br>
                        Request a special delivery time (for a fee)<br>
                        Add delivery notes<br>
                        Set vacation time for all deliveries<br>
                        Request to hold your package for 14 days (this is the longest consecutive time period; must wait
                        6 days to do another hold)<br>
                        Use the FedEx “Virtual Assistant” and Customer Chat
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel class="border-txt">
                    <mat-expansion-panel-header class="header-border">
                        Return an Item or Order
                    </mat-expansion-panel-header>
                    <p style="text-align: left !important;">
                        To return or exchange products for any reason, please follow these steps:<br><br>

                        <strong>Prepare Your Return:</strong> Clearly mark "RMA" and your order number on the outside of the box. 
                        Return all contents within 60 days of receiving your order. Include any specific details inside the package.<br>
                        
                        Multiple Boxes: If returning more than one box, ensure each box is labeled with "RMA - Order Number."<br><br>
                        
                        <strong>Return Requirements:</strong> You must return each item for which you wish to receive a refund. 
                        Even if a product is fully used, the container(s) must be included for a refund to be issued. 
                        Returns must arrive at our corporate office within 60 days of the original receipt date. 
                        Late returns will be rejected unless prior approval for credit has been obtained.<br><br>

                        <strong>Shipping Recommendations:</strong><br><br>
                        We highly recommend using a traceable shipping method for returns, as 
                        Purium Health Products is not responsible for lost or damaged items. If sending products via U.S. mail, 
                        please use registered, certified, or return receipt mail.<br><br>

                        <strong>Processing Time:</strong><br><br>
                        Returns typically take 2-3 weeks to process. Refunds can only be issued to the credit card used for the 
                        original order. Please confirm whether the credit card is still valid; if not, your refund will be 
                        issued via check from Purium Health Products. You will receive an email confirmation from the 
                        Returns Department once your refund is processed.<br><br>

                        <strong>Will Call Returns:</strong><br><br>
                        Returns will be accepted at Will Call; however, they will not be processed on the spot.<br><br>

                        <strong>Return Address:</strong><br><br>
                        Please send all products you wish to return or request a refund to:<br><br>
                        US Returns<br>
                        Purium<br>
                        2610 Homestead Place<br>
                        Rancho Dominguez, CA 90220<br><br>

                        Canada Returns<br>
                        Purium<br>
                        130 - 1959 152nd Street<br>
                        STE 512<br>
                        Surrey, BC V4A 0C4<br>
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel class="border-txt">
                    <mat-expansion-panel-header class="header-border">
                        Creating, Editing, or Canceling Monthly Subscriptions
                    </mat-expansion-panel-header>
                    <p style="text-align: left !important;">
                        Go to <a href="https://ishoppurium.com/">ishoppurium.com</a> and login to your account. Click on
                        the Monthly Subscription tab to modify.
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel class="border-txt">
                    <mat-expansion-panel-header class="header-border">
                        Enrolling or Upgrading to Brand Partner
                    </mat-expansion-panel-header>
                    <p style="text-align: left !important;">
                        To Enroll as a Brand Partner - If you are new to Purium (not in our system), please enroll at
                        puriumenrollment.com<br><br>

                        To Upgrade from a Customer to a Brand Partner - Login at <a
                            href="https://ishoppurium.com/">ishoppurium.com</a> and choose the Upgrade to Brand Partner
                        button.<br>
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel class="border-txt">
                    <mat-expansion-panel-header class="header-border">
                        Redeem Rewards Points
                    </mat-expansion-panel-header>
                    <div style="text-align: left !important;" fxFlex fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="20px">
                        <div fxFlex fxLayout="column">
                            <div fxFlex fxLayout="row" fxLayoutAlign="center start" class="height-img">
                                <img src="../../../../assets/support_img/RewardsPointsLogo.png" class="img-logo" />
                            </div>
                            <div fxFlex fxLayout="column">
                                <p class="p-col">
                                    THE MORE YOU BUY,<br>
                                    THE MORE YOU EARN
                                </p>
                                <p class="p-col2">
                                    Earn 1 Point for every $1 you spend per subtotal amount.
                                </p>
                                <ul class="p-col3">
                                    <li>Earn 1 Point for every $1 spent on your purchases (subtotal amount)</li>
                                    <li>1000 points = $10</li>
                                    <li>Points can be redeemed in 1000 point increments</li>
                                    <li>5000 points is the maximum amount of points that can be redeemed on an Order</li>
                                </ul>
                                <p class="p-col">
                                    EARN MORE WITH SMART ORDER
                                </p>
                                <p class="p-col2">
                                    Earn 1.5 Points for every $1 spent on your purchases (subtotal amount).
                                </p>
                            </div>
                        </div>
                        <div fxFlex fxLayout="row">
                            <img src="../../../../assets/support_img/Screenshotdd.png" class="img-support" />
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="border-txt">
                    <mat-expansion-panel-header class="header-border">
                        Will Call Details & Instructions
                    </mat-expansion-panel-header>
                    <p style="text-align: left !important;">
                        Ready for pickup anytime Monday - Friday 8:00 am - 5:30 pm!.<br><br>

                        Note: Will Call Order must be picked up within 10 days of receiving email. If not, the order
                        will be cancelled and refunded. <br><br>

                        Will Call Hours<br>
                        Monday - Friday<br>
                        8am - 5:30pm PT <br><br>

                        Will Call Address:<br>
                        2610 Homestead Place<br>
                        Rancho Dominguez, CA 90220<br><br>

                        Park in visitor parking, ring the doorbell near the Will Call sign & show your ID.<br><br>


                        Customers who purchased the order must show ID and be physically present to pick-up and, unless
                        other arrangements have been previously made through customer care.<br><br>

                        <a href="https://bit.ly/PuriumCustomerCare">https://bit.ly/PuriumCustomerCare</a>.
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel class="border-txt">
                    <mat-expansion-panel-header class="header-border">
                        DREAMS Phone App Support
                    </mat-expansion-panel-header>
                    <p style="text-align: left !important;">
                        Telephone: 1 (800) 794-8158<br>
                        Email: support@mysecureoffice.com
                    </p>
                </mat-expansion-panel>
            </mat-accordion>
            <br>
        </div>
        <div fxLayoutAlign="space-evenly center" class="pt-5 pb-5 mt-5 bg-image" 
        [style.background-image]="'url(' + IMAGE_URL + '/img/support-bg.png)'"
        style="
    max-width:100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    color: white;
    width: 100%;">
            <div class="container text-center pt-5 pb-5">
                <h1>DO YOU HAVE ANY ADDITIONAL QUESTIONS?</h1>
                <br><br>
                <h3>Purium Blogs</h3>
                <p>The Purium blogs contain tons of information about product and business at your fingertips.
                    <br>
                    Use the search bar to find blogs by product name or topics.
                </p>
                <div class="row">
                    <div class="col-6">
                        <a class="btn" href="https://blog.puriumcorp.com/" target="_blank"
                            aria-label="PRODUCT BLOG - Opens in a new tab"
                            style="background-color: #33A744; color: white; border-radius: 0; float: right;">
                            PRODUCT BLOG
                        </a>
                    </div>
                    <div class="col-6">
                        <a class="btn" href="https://www.isharepurium.com/blog/" target="_blank"
                            aria-label="BUSINESS BLOG - Opens in a new tab"
                            style="background-color: #33A744; color: white; border-radius: 0; float: left;">
                            BUSINESS BLOG
                        </a>
                    </div>
                </div>
                <br><br>
                <h3>Purium Lifestyle Transformation Support Group</h3>
                <p>Use the search bar in our Support Group to see how the Purium community has experienced your question
                    or
                    concern.
                </p>
                <a class="btn" href="https://www.facebook.com/groups/PuriumLifestyleTransformation" target="_blank"
                    aria-label="JOIN GROUP - Opens in a new tab"
                    style="background-color: #33A744; color: white; border-radius: 0;">
                    JOIN GROUP
                </a>
                <br><br>
                <h3>Purium Business Group</h3>
                <p>This is a Private Facebook Group dedicated to Purium Brand Partners.<br>
                    Here you will find training material, social media posts for sharing, product information, sale
                    updates,
                    articles, event updates and additional resources to help you grow as a Purium Brand Partner.
                </p>
                <a class="btn" href="https://www.facebook.com/groups/PuriumTribe/" target="_blank"
                    aria-label="JOIN GROUP - Opens in a new tab"
                    style="background-color: #33A744; color: white; border-radius: 0;">
                    JOIN GROUP
                </a>
            </div>
        </div>
    </div>
    <app-footercomponent style="width: 100%;"></app-footercomponent>
</div>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css">