import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { RestService } from 'app/shared/rest.service';
import { AuthService } from 'app/shared/auth.service';
import { CalculateRetailOrderService } from 'app/shared/calculateRetailOrder.service';
import { CalculateAutoOrderService } from 'app/shared/calculateAutoOrder.service';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { CommonService } from 'app/shared/common.service';
import { IMAGE_URL, objectToParams } from 'app/constants';
import * as moment from 'moment';

@Component({
  selector: 'app-confirmation',
  templateUrl: './Confirmation.component.html',
  styleUrls: ['./Confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationComponent implements OnInit {
  IMAGE_URL = IMAGE_URL;
  backofficeUrl: any;
  cardNum: any;
  zoom = 14;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 50,
    minZoom: 4,
  };
  markers:any[] = [];
  latitude:any;
  longitude:any;
  userData: any;
  subscriptionURL: any;
  ultLifestyleFlow:any;
  smartOrderDateForLifestyle:any;
  AutoOrderDetails:any;
  creditCardNum: any;
  paymentDetails:any;
  siteType: any;
  constructor(
    public _enrollSession: EnrollSessionService,
    public router: Router,
    private cookieService: CookieService,
    public _restService: RestService,
    public _auth: AuthService,
    public _calculateRetailOrderService: CalculateRetailOrderService,
    public _calculateAutoOrder: CalculateAutoOrderService,
    public _sharedMethodService: SharedMethodService,
    public _commonService: CommonService
  ) { 
    this.creditCardNum = localStorage.getItem('PaymentMethodDifferent');
    this.paymentDetails = JSON.parse(localStorage.getItem('billingInfo')) || {};
    this.siteType = sessionStorage.getItem("siteType");
  }

  ngOnInit(): void {
    this._enrollSession.editTransformationPack = '';
    if(this._auth.isLoggedIn()){
      var num =  (this.creditCardNum === null || this.creditCardNum === 'true') ? this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber || this.paymentDetails?.primaryCreditCardField?.creditCardNumberDisplayField : this.paymentDetails?.primaryCreditCardField?.creditCardNumberDisplayField;
    }
    else{
      var num =  this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber;
    }
    this.cardNum = num?.toString().slice(-4);
    var address1 = this._enrollSession.data.RetailOrderSummary.shippingFormData.address1;
    var address2 = this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 ? this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 : '';
    var city = this._enrollSession.data.RetailOrderSummary.shippingFormData.City;
    var zipcode = this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode;
    var state = this._enrollSession.data.RetailOrderSummary.shippingFormData.State;
    var country = this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode;
    var address = address1+' '+address2+', '+city+', '+state+' '+zipcode+' '+country;
    var that = this;

    setTimeout(() => {
      this.markers.push({
        position: {
          lat: that.latitude,
          lng: that.longitude,
        },
        options: { animation: google.maps.Animation.BOUNCE },
      });
    }, 3000);
    
    this.userData = JSON.parse(this._auth.getUserData());
    this.ultLifestyleFlow = sessionStorage.getItem("onUltLifestyleFlow");
    this.smartOrderDateForLifestyle = moment().add(1, 'months').format('MM/DD/YYYY');
  }

  redirectToBackoffice() {
    let customerId = this._enrollSession.submitApplicationData.CustomerID || this.userData.CustomerID;
    let windowref = window.open();
    this._restService.SilentLogin(customerId).subscribe((res: any) => {
      this.backofficeUrl = res;
      windowref.location = this.backofficeUrl, "_blank";
    }, error => {
      console.log("In error")
    })
  }

  ClicktoSubscription(){
    if (this._auth.isLoggedIn()) {
      let windowref = window.open();
      let ssoToken = sessionStorage.getItem('SSOToken');
      this.subscriptionURL = 'https://phpsmartorder.wsicloud.net/login?SSOToken=' + ssoToken;
      windowref.location = this.subscriptionURL, "_blank";
    }
    else {
      let customerId = this._enrollSession.submitApplicationData.CustomerID || this.userData.CustomerID;
      let username = this._enrollSession.data.RetailOrderSummary?.accountInfoData?.loginName;
      var request = objectToParams({
        client_id: customerId,
        grant_type: "password",
        userName: username
      });
      this._restService.Token(request).subscribe((result: any) => {
        if (result && result.access_token) {
          sessionStorage.setItem('SSOToken', result.SSOToken);
          let windowref = window.open();
          let ssoToken = sessionStorage.getItem('SSOToken');
          this.subscriptionURL = 'https://phpsmartorder.wsicloud.net/login?SSOToken=' + ssoToken;
          windowref.location = this.subscriptionURL, "_blank";
        }
      });
    }
  }



  zoomIn() {
    if (this.zoom < this.options.maxZoom) this.zoom++
  }

  zoomOut() {
    if (this.zoom > this.options.minZoom) this.zoom--
  }

  goToTodayOrderPage() {
    this.router.navigate(['/TodayOrders'])
  }
  @HostListener('window:beforeunload', ['$event'])
  clearStorage(event) {
    if (!this._enrollSession.data.IsLoggedIn) {
      this._enrollSession.data = {};
      this._enrollSession.submitApplicationData = {};
      this._enrollSession.editTransformationPack = '';
      sessionStorage.removeItem('newCustomer');
      sessionStorage.clear();
      localStorage.clear();
    }
    if(this._enrollSession.data.IsLoggedIn)
    {
      this._enrollSession.data.RetailOrderSummary = {};
      this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
      this.cookieService.delete('access_token', '/');
      this.cookieService.delete('X-Auth', '/');
      this.cookieService.deleteAll('/');
      this._enrollSession.data.IsLoggedIn = false;
      this._enrollSession.data.LoggedInUserData = {};
      this._enrollSession.data.priceType = 0;
      sessionStorage.setItem('onUltLifestyleFlow', 'false');
      localStorage.setItem("isExistingAutoship", "false");
      sessionStorage.clear();
      localStorage.clear();
    }
    this._calculateRetailOrderService.DiscountValue = '';
    this._calculateRetailOrderService.totalRemainingBalance = '';
    this._calculateRetailOrderService.isSelected = false;
    this.router.navigate(['/', 'AllProducts']);
  }
  ngOnDestroy(): void
  {
    if (!this._enrollSession.data.IsLoggedIn) {
      this._enrollSession.data = {};
      this._enrollSession.submitApplicationData = {};
      this._enrollSession.editTransformationPack = '';
      sessionStorage.removeItem('newCustomer');
      sessionStorage.clear();
      localStorage.clear();
    }
    if(this._enrollSession.data.IsLoggedIn)
    {
      this._enrollSession.data.RetailOrderSummary = {};
      this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
      this.cookieService.delete('access_token', '/');
      this.cookieService.delete('X-Auth', '/');
      this.cookieService.deleteAll('/');
      this._enrollSession.data.IsLoggedIn = false;
      this._enrollSession.data.LoggedInUserData = {};
      this._enrollSession.data.priceType = 0;
      sessionStorage.setItem('onUltLifestyleFlow', 'false');
      localStorage.setItem("isExistingAutoship", "false");
      sessionStorage.clear();
      localStorage.clear();
    }
    this._calculateRetailOrderService.DiscountValue = '';
    this._calculateRetailOrderService.totalRemainingBalance = '';
    this._calculateRetailOrderService.totalRemainingBalanceForSmartOrder = '';
    this._calculateRetailOrderService.isSelected = false;
    this.router.navigate(['/', 'AllProducts']);
  }
}
