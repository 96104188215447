import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as _ from 'underscore';
import { RestService } from 'app/shared/rest.service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/shared/auth.service';
import { OrderSubItemComponentCustom } from 'app/shared/Orders-Sub-Item-Custom/Orders-Sub-Item-Custom.component';
import { MatSidenav } from '@angular/material/sidenav';
import { productPopupComponent } from 'app/shared/Product-Popup/Product-Popup.component';

@Component({
    selector: 'Enhance',
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    templateUrl: './Enhance.component.html',
    styleUrls: ['./Enhance.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EnhanceComponent implements OnInit {
    productList: any[] = [];
    routPath: any = "";
    EnhanceTranformation: any[] = [];
    onSearchEnhance:  any;
    siteType: any;
    panelOpenState : any;
    customertype: any;
    isDesktopView: boolean;
    constructor(
        private _restService: RestService,
        public _enrollSession: EnrollSessionService,
        public _sharedMethodService: SharedMethodService,
        private _matDialog: MatDialog,
        private router : Router,
        private _auth: AuthService,
        private _toastr: ToastrService
    ) { 
    
        this.siteType = sessionStorage.getItem("siteType");
        sessionStorage.setItem('onUltLifestyleFlow', 'true');
        if (((this._enrollSession.data.RetailOrderSummary == undefined) || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.Enrollpack.length == 0)) && (this.siteType && this.siteType!=null && this.siteType!='')) {
            if (this._enrollSession.data.RetailOrderSummary.Enrollpack.length == 0) {
                this._toastr.warning("Please select Transformation Pack", '');
            }
            sessionStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
            localStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
            this.router.navigate(['/AllProducts'], {
                queryParamsHandling: 'preserve',
            })
        }
      
    }

    ngOnInit(): void {
        this.getAllProduct();
        this.routPath = this.siteType == 'ultlifestyle'?"Shipping":"Subscribe";
        this._sharedMethodService.isOpen = true;
        if(window.innerWidth > 550){
            this.isDesktopView = true;
        }
        else{
            this.isDesktopView =  false;
        }
    }

    getAllProduct() {
        const request = {
            'EnrollmentType': '',
            'CustomerType': 22,
            'WebCategoryId': 463,
            'isUpgradeCustomer': false,
            'IsSubscriptionOrder': false

        };
        this._restService.getProducts(request).subscribe((res: any) => {
            var listData = res.Items;
            var uniqueproductListData = listData.filter((tag, index, array) => array.findIndex(t => t.ItemCode == tag.ItemCode && t.Description == tag.Description && t.Price == tag.Price) == index);
            this.productList = uniqueproductListData;
            var allProductList = uniqueproductListData;
            var EnhanceTranformation = [];
            allProductList.forEach((item) => {
                if (item.CategoryID === 465) {
                    if((item.ItemCode!='2754') && (item.ItemCode!='2016'))
                    {
                        EnhanceTranformation.push(item);
                    }
                }
            });
            let enhanceTransformProducts = cloneDeep(EnhanceTranformation);
            this.EnhanceTranformation = enhanceTransformProducts;
        });
    }
   
    productInfo(item) {
        const dialogRef = this._matDialog.open(productPopupComponent, {
            width: '650px',

            panelClass: 'product-info',
            data: {
                item: item
            },
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == 'Success') {
            }
        });
    }

    onSearchChangeEnhance(event) {
        this.panelOpenState = true;
        this._sharedMethodService.headerSearchValue = event.target.value;
    }

    getUserData(customerDetail) {
        if(this._auth.isLoggedIn()){
            this.customertype = customerDetail.CustomerType
        }
    }

    addToCart(item: any, quantity: any, type: any, sideNav: MatSidenav) {
        if (item.KitMembers && item.KitMembers.length > 0) {
            const dialogRef = this._matDialog.open(OrderSubItemComponentCustom, {
                width: '600px',
                panelClass: ['order-sub-item', 'sub-item-modal'],
                data: {
                    item: item,
                    type:type,
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result.event == 'submit') {
                    this._sharedMethodService.toggleSidenavforaddtocart();
                }
            });
        }else
        {
            this._sharedMethodService.ContinueRetailOrder(item, quantity, type);
            this._sharedMethodService.toggleSidenavforaddtocart();
        }
    }

    getHeaderSearchedValue(){
        return this._sharedMethodService.headerSearchValue;
    }
}