<div id="NewsLettersSignup">
    <!-- -------------------------    Customer Image Banner for Desktop   ------------------------------->
    <ng-container *ngIf="(_router.url.includes('getnews')) && !_enrollSession.mobileViewMethod()">
        <div id="TopBanner" class="row"
            [ngClass]="{'header-padding':_sharedMethodService.isOpen,'header-padding-full':!_sharedMethodService.isOpen}">
            <div fxFlex="100" class="wp-block-heading has-text-align-center" id="welcome-banner"
                [ngClass]="{'p-20': (!_commonService.SubscribeClickCheck && !_sharedMethodService.giftcardNameMethod())}">
                <span fxFlex="15"
                    *ngIf="(_commonService.SubscribeClickCheck || _sharedMethodService.giftcardNameMethod())"
                    id="userImagespan"><img
                        src="{{_enrollSession.customerImage || IMAGE_URL+'/images/avatars/profile_icon.png'}}"
                        [attr.onError]="'this.src=\'' + IMAGE_URL + '/images/avatars/profile_icon.png\''" class="userImage"></span>
                <div fxFlex="79" class="m-auto">
                    <span class="welcome-text"
                        *ngIf="(!_commonService.SubscribeClickCheck && _sharedMethodService.giftcardNameMethod())">Join
                        {{_enrollSession.data.RetailEnroll.webAlias.split(' ')[0]}}'s</span>
                    <span class="welcome-text" *ngIf="(_commonService.SubscribeClickCheck)">Welcome to
                        {{_enrollSession.data.RetailEnroll.webAlias.split(' ')[0]}}'s</span>
                    <br *ngIf="(_commonService.SubscribeClickCheck || _sharedMethodService.giftcardNameMethod())">
                    <span *ngIf="(!_commonService.SubscribeClickCheck && !_sharedMethodService.giftcardNameMethod())"
                        class="p-15">Join Purium’s Superfoods Community</span>
                    <span *ngIf="((_commonService.SubscribeClickCheck) || _sharedMethodService.giftcardNameMethod())"
                        class="welcome-text">Superfoods Community</span>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="_router.url.includes('getnews') && _enrollSession.mobileViewMethod()">
        <div id="TopBanner"
            [ngClass]="{'header-padding':_sharedMethodService.isOpen,'header-padding-full':!_sharedMethodService.isOpen}">
            <div class="wp-block-heading has-text-align-center" id="welcome-banner">
                <span *ngIf="(_commonService.SubscribeClickCheck || _sharedMethodService.giftcardNameMethod())"
                    id="userImagespan"><img
                        src="{{_enrollSession.customerImage || IMAGE_URL+'/images/avatars/profile_icon.png'}}"
                        [attr.onError]="'this.src=\'' + IMAGE_URL + '/images/avatars/profile_icon.png\''" class="userImage"></span>

                <div class="m-auto p-15">
                    <span class="welcome-text"
                        *ngIf="(!_commonService.SubscribeClickCheck && _sharedMethodService.giftcardNameMethod())">Join
                        {{_enrollSession.data.RetailEnroll.webAlias.split(' ')[0]}}'s</span>
                    <span class="welcome-text" *ngIf="(_commonService.SubscribeClickCheck)">Welcome to
                        {{_enrollSession.data.RetailEnroll.webAlias.split(' ')[0]}}'s</span>
                    <br *ngIf="(_commonService.SubscribeClickCheck || _sharedMethodService.giftcardNameMethod())">
                    <span *ngIf="(!_commonService.SubscribeClickCheck && !_sharedMethodService.giftcardNameMethod())"
                        class="p-15">Join Purium’s Superfoods Community</span>
                    <span *ngIf="(_commonService.SubscribeClickCheck || _sharedMethodService.giftcardNameMethod())"
                        class="welcome-text">Superfoods Community</span>
                </div>
            </div>
        </div><br>
    </ng-container>


    <ng-container *ngIf="!_enrollSession.mobileViewMethod()">
        <div class="heading-green-leaf">
            <img [src]="IMAGE_URL+'/images/logos/PuriumLogoOnNewsLetter.png'" alt="" id="head_logo"
                class="head_logoGreenLeaf">
        </div>
        <div class="">
            <div
                [ngClass]="{'m-50':_commonService.SubscribeClickCheck,'divAlignment':_commonService.SubscribeClickCheck}">
                <div class="text-center" *ngIf="(_commonService.SubscribeClickCheck)">
                    <p class="logo-below-text1" *ngIf="_commonService.EmailCheck">Hooray! You're already a
                        valued<br> member of our community!</p>
                    <p class="logo-below-text1" *ngIf="!_commonService.EmailCheck">
                        <span>{{_enrollSession.data.RetailEnroll.webAlias?.split(' ')[0]}}</span> is excited to welcome
                        you <br>to the
                        Purium community.
                    </p>
                    <div>
                        <p class="font-familyjose fs-35 red-c" *ngIf="_commonService.EmailCheck">This email is already
                            registered.</p>
                        <br><br>
                        <p class="font-familyjose fs-35" *ngIf="!_commonService.EmailCheck">Thank you for signing up!
                        </p>
                        <p class="font-familyjose fs-35"> Save 25% on all $200+ orders </p>
                        <p class="font-familyjose fs-35">at <a class="black-c  fw-700 cursor-pointer"
                                href="https://ishoppurium.com">ishoppurium.com</a></p>
                        <br><br>
                        <div class="text-center">
                            <a class="main-btn shop-btn mt-40" (click)="ShopNow()">Shop Now</a>
                        </div>
                        <br><br><br>
                        <div class="mt-30" *ngIf="_commonService.EmailCheck">
                            <p class="customerSupport">If you need support contact <span class="underline"
                                    (click)="RedirectToSupport()">customer care</span>. We are here for you. </p>
                        </div>
                    </div>
                </div>
                <div class="p-50 width-100" *ngIf="!_commonService.SubscribeClickCheck">
                    <div class="custom-preference-container">
                        <div class="width-100 custom-preference-heading">
                            <p class="sub-heading">Tell us what solution you're curious about so we can personalize your preferences.</p>
                            <p class="fs-16">Check all that apply (select at least 1 to submit)</p>
                        </div>
                        <div class="width-100 custom-preference-options">
                            <div class="custom-preference-option" *ngFor="let preference of customPreferences">
                                <input class="custom-preference-checkbox" type="checkbox" [(ngModel)]="preference.isChecked" [value]="preference.isChecked">
                                <span>{{preference.title}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-50 width-100" fxFlex="50" *ngIf="!_commonService.SubscribeClickCheck">
                    <p class="signupheading" *ngIf="!_commonService.SubscribeClickCheck">Sign up now!</p>
                    <br *ngIf="(_commonService.SubscribeClickCheck) && !_commonService.EmailCheck">
                    <br *ngIf="(_commonService.SubscribeClickCheck) && !_commonService.EmailCheck">
                    <div class="border-box">
                        <div>
                            <div class="w-form">
                                <form [formGroup]="SignUpInfoForm" id="email-form1" name="email-form"
                                    class="signup-info-form custom">
                                    <div fxLayout="column">
                                        <div class="mt-20" fxLayout="row" fxFlex="100"
                                            fxLayoutAlign="space-between none">
                                            <div fxLayout="column" fxFlex="100">
                                                <div>
                                                    <input type="text" class="form-field w-input" maxlength="30"
                                                        name="First-Name" formControlName="FirstName"
                                                        placeholder="First Name" id="First-Name" required>
                                                </div>
                                                <span class="help-block"
                                                    *ngIf="!SignUpInfoForm.get('FirstName')?.valid&&SignUpInfoForm.get('FirstName')?.touched">
                                                    <span
                                                        *ngIf="SignUpInfoForm.get('FirstName').errors['required']">This
                                                        field is required</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mt-20" fxLayout="row" fxFlex="100">
                                            <div fxLayout="column" fxFlex="100">
                                                <div>
                                                    <input type="text" class="form-field  w-input" maxlength="30"
                                                        name="Last-Name" formControlName="LastName"
                                                        placeholder="Last Name" id="Last-Name-2" required>
                                                </div>
                                                <span class="help-block"
                                                    *ngIf="!SignUpInfoForm.get('LastName')?.valid&&SignUpInfoForm.get('LastName')?.touched">
                                                    <span *ngIf="SignUpInfoForm.get('LastName').errors['required']">This
                                                        field is required</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mt-20" fxLayout="row" fxFlex="100">
                                            <div fxLayout="column" fxFlex="100">
                                                <div>
                                                    <input type="text" class="form-field w-input" maxlength="50"
                                                        name="Email" formControlName="Email" placeholder="Email"
                                                        id="emailAddress" required>
                                                </div>
                                                <span class="help-block"
                                                    *ngIf="!SignUpInfoForm.get('Email').valid&&SignUpInfoForm.get('Email').touched&&!_auth.isLoggedIn()">
                                                    <span
                                                        *ngIf="!SignUpInfoForm.get('Email').errors['required'] && !SignUpInfoForm.get('Email').valid">E-mail
                                                        is invalid</span>
                                                    <span *ngIf="SignUpInfoForm.get('Email').errors['required']">This
                                                        field is required</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mt-20" fxLayout="row" fxFlex="100">
                                            <div fxLayout="column" fxFlex="100">
                                                <div>
                                                    <input type="text" class="form-field w-input input-content"
                                                        maxlength="10" name="Phone" formControlName="Phone"
                                                        placeholder="Phone Number (Optional)" id="phoneNumber">
                                                </div>
                                                <span class="help-block"
                                                    *ngIf="!SignUpInfoForm.get('Phone').valid&&SignUpInfoForm.get('Phone').touched">
                                                    <span *ngIf="!SignUpInfoForm.get('Phone').valid">Phone
                                                        Number is invalid</span>
                                                </span>

                                            </div>
                                        </div>
                                        <div class="mt-20" fxLayout="row" fxFlex="100">
                                            <div fxLayout="column" fxFlex="100">
                                                <div>
                                                    <input type="text" class="form-field w-input" maxlength="50"
                                                        name="referralCode" formControlName="referralCode"
                                                        placeholder="Referral Code" id="referralCode"
                                                        [(ngModel)]="_enrollSession.data.RetailOrderSummary.referredFormData.referralCode"
                                                        required>
                                                </div>
                                                <span class="help-block"
                                                    *ngIf="!SignUpInfoForm.get('referralCode').valid&&SignUpInfoForm.get('referralCode').touched">
                                                    <span
                                                        *ngIf="SignUpInfoForm.get('referralCode').errors['required']">This
                                                        field is required</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="text-center">
                                <button class="main-btn subscribe-btn mt-40" (click)="SubscribeButton()">
                                    <span class="subscribe-btn-text">
                                        Subscribe
                                        <svg class="subscribe-icon"  viewBox="0 0 106 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M105.061 13.0607C105.646 12.4749 105.646 11.5251 105.061 10.9393L95.5147 1.3934C94.9289 0.807611 93.9792 0.807611 93.3934 1.3934C92.8076 1.97919 92.8076 2.92893 93.3934 3.51472L101.879 12L93.3934 20.4853C92.8076 21.0711 92.8076 22.0208 93.3934 22.6066C93.9792 23.1924 94.9289 23.1924 95.5147 22.6066L105.061 13.0607ZM0 13.5H104V10.5H0V13.5Z" fill="white"/>
                                        </svg></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-30">
            <p class="privacyText" *ngIf="!_commonService.SubscribeClickCheck">By submitting your information, you are
                agreeing to <span class="underline" (click)="OpenToPrivacyPolicyPopup()">Purium's Privacy Policy.</span>
            </p>
            <br>
        </div>
    </ng-container>

    <ng-container *ngIf="_enrollSession.mobileViewMethod()">
        <div class="">
            <div>
                <div class="text-center" *ngIf="(_commonService.SubscribeClickCheck)">
                    <p class="logo-below-text1 fs-20" *ngIf="_commonService.EmailCheck">Hooray! You're already a
                        valued<br> member of our community!</p>
                    <p class="logo-below-text1 fs-20" *ngIf="!_commonService.EmailCheck">
                        <span>{{_enrollSession.data.RetailEnroll.webAlias?.split(' ')[0]}}</span> is excited to welcome
                        you <br>to the
                        Purium community.
                    </p>
                    <div>
                        <p class="font-familyjose fs-20 red-c" *ngIf="_commonService.EmailCheck">This email is already
                            registered.</p>
                        <br><br>
                        <p class="font-familyjose fs-20" *ngIf="!_commonService.EmailCheck">Thank you for signing up!
                        </p>
                        <p class="font-familyjose fs-20"> Save 25% on all $200+ orders </p>
                        <p class="font-familyjose fs-20">at <a class="black-c  fw-700 cursor-pointer"
                                href="https://ishoppurium.com">ishoppurium.com</a></p>
                        <br><br>
                        <div class="text-center">
                            <a class="main-btn shop-btn mt-40" (click)="ShopNow()">Shop Now</a>
                        </div>
                        <br><br><br>
                        <div class="mt-30" *ngIf="_commonService.EmailCheck">
                            <p class="customerSupport">If you need support contact <span class="underline"
                                    (click)="RedirectToSupport()">customer care</span>. We are here for you. </p>
                        </div>
                    </div>
                </div>
                <div class="p-10 width-100" *ngIf="!_commonService.SubscribeClickCheck">
                    <div class="custom-preference-container">
                        <div class="width-100 custom-preference-heading">
                            Tell us what solution you're curious about so we can personalize your preferences.
                            <p class="fs-12">Check all that apply (select at least 1 to submit)</p>
                        </div>
                        <div class="width-100 custom-preference-options">
                            <div class="custom-preference-option" *ngFor="let preference of customPreferences">
                                <input class="custom-preference-checkbox" type="checkbox" [(ngModel)]="preference.isChecked" [value]="preference.isChecked">
                                <span>{{preference.title}}</span>
                            </div>
                        </div>
                    </div>
                    <p class="signupheading">Sign up now!</p>
                    <div class="border-box">
                        <div>
                            <div class="w-form">
                                <form [formGroup]="SignUpInfoForm" id="email-form1" name="email-form"
                                    class="signup-info-form custom">
                                    <div fxLayout="column">
                                        <div class="mt-10" fxLayout="row" fxFlex="100"
                                            fxLayoutAlign="space-between none">
                                            <div fxLayout="column" fxFlex="100">
                                                <div>
                                                    <input type="text" class="form-field w-input" maxlength="30"
                                                        name="First-Name" formControlName="FirstName"
                                                        placeholder="First Name" id="First-Name" required>
                                                </div>
                                                <span class="help-block"
                                                    *ngIf="!SignUpInfoForm.get('FirstName')?.valid&&SignUpInfoForm.get('FirstName')?.touched">
                                                    <span
                                                        *ngIf="SignUpInfoForm.get('FirstName').errors['required']">This
                                                        field is required</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mt-10" fxLayout="row" fxFlex="100">
                                            <div fxLayout="column" fxFlex="100">
                                                <div>
                                                    <input type="text" class="form-field  w-input" maxlength="30"
                                                        name="Last-Name" formControlName="LastName"
                                                        placeholder="Last Name" id="Last-Name-2" required>
                                                </div>
                                                <span class="help-block"
                                                    *ngIf="!SignUpInfoForm.get('LastName')?.valid&&SignUpInfoForm.get('LastName')?.touched">
                                                    <span *ngIf="SignUpInfoForm.get('LastName').errors['required']">This
                                                        field is required</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mt-10" fxLayout="row" fxFlex="100">
                                            <div fxLayout="column" fxFlex="100">
                                                <div>
                                                    <input type="text" class="form-field w-input" maxlength="50"
                                                        name="Email" formControlName="Email" placeholder="Email"
                                                        id="emailAddress" required>
                                                </div>
                                                <span class="help-block"
                                                    *ngIf="!SignUpInfoForm.get('Email').valid&&SignUpInfoForm.get('Email').touched&&!_auth.isLoggedIn()">
                                                    <span
                                                        *ngIf="!SignUpInfoForm.get('Email').errors['required'] && !SignUpInfoForm.get('Email').valid">E-mail
                                                        is invalid</span>
                                                    <span *ngIf="SignUpInfoForm.get('Email').errors['required']">This
                                                        field is required</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mt-10" fxLayout="row" fxFlex="100">
                                            <div fxLayout="column" fxFlex="100">
                                                <div>
                                                    <input type="text" class="form-field w-input" maxlength="10"
                                                        name="Phone" formControlName="Phone"
                                                        placeholder="Phone Number (Optional)" id="phoneNumber">
                                                </div>
                                                <span class="help-block"
                                                    *ngIf="!SignUpInfoForm.get('Phone').valid&&SignUpInfoForm.get('Phone').touched">
                                                    <span *ngIf="SignUpInfoForm.get('Phone').errors['required']">This
                                                        field is required</span>
                                                    <span
                                                        *ngIf="!SignUpInfoForm.get('Phone').valid && !SignUpInfoForm.get('Phone').errors['required']">Phone
                                                        Number is invalid</span>
                                                </span>

                                            </div>
                                        </div>
                                        <div class="mt-10" fxLayout="row" fxFlex="100">
                                            <div fxLayout="column" fxFlex="100">
                                                <div>
                                                    <input type="text" class="form-field w-input" maxlength="50"
                                                        name="referralCode" formControlName="referralCode"
                                                        placeholder="Referral Code" id="referralCode"
                                                        [(ngModel)]="_enrollSession.data.RetailOrderSummary.referredFormData.referralCode"
                                                        required>
                                                </div>
                                                <span class="help-block"
                                                    *ngIf="!SignUpInfoForm.get('referralCode').valid&&SignUpInfoForm.get('referralCode').touched">
                                                    <span
                                                        *ngIf="SignUpInfoForm.get('referralCode').errors['required']">This
                                                        field is required</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="text-center">
                                <button class="main-btn subscribe-btn mt-40" (click)="SubscribeButton()">
                                    <span class="subscribe-btn-text">
                                        Subscribe
                                        <svg class="subscribe-icon"  viewBox="0 0 106 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M105.061 13.0607C105.646 12.4749 105.646 11.5251 105.061 10.9393L95.5147 1.3934C94.9289 0.807611 93.9792 0.807611 93.3934 1.3934C92.8076 1.97919 92.8076 2.92893 93.3934 3.51472L101.879 12L93.3934 20.4853C92.8076 21.0711 92.8076 22.0208 93.3934 22.6066C93.9792 23.1924 94.9289 23.1924 95.5147 22.6066L105.061 13.0607ZM0 13.5H104V10.5H0V13.5Z" fill="white"/>
                                        </svg></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-30 p-10">
                    <p class="privacyText" *ngIf="!_commonService.SubscribeClickCheck">By submitting your information,
                        you are agreeing to <span class="underline" (click)="OpenToPrivacyPolicyPopup()">Purium's
                            Privacy Policy.</span></p>
                    <br>
                </div>
                <div class="text-center p-10" *ngIf="!_commonService.SubscribeClickCheck">
                    <a class="">
                        <img [src]="IMAGE_URL+'/images/newslettersignup/PuriumLogoFor Mobile.png'" alt=""
                            id="head_logo" class="head_logoGreenLeaf m-tb-20">
                    </a>
                </div>

            </div>
        </div>
    </ng-container>

</div>