
import { Component, OnInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { StepperService } from 'app/shared/stepper.service';
import { RestService } from 'app/shared/rest.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { MatDialog } from '@angular/material/dialog';
import { OrderSubItemComponent } from 'app/shared/Orders-Sub-Item/Orders-Sub-Item.component';
import { productInfoComponent } from 'app/shared/Product-info/Product-info.component';
import * as _ from 'underscore';
import { LifestyleSmartorderSubscriptionComponent } from 'app/shared/LifestyleSmartorderSubscription/LifestyleSmartorderSubscription.component';
import { CalculateRetailOrderService } from 'app/shared/calculateRetailOrder.service';
import { AuthService } from 'app/shared/auth.service';
@Component({
    selector: 'app-order-review',
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    templateUrl: './order-review.component.html',
    styleUrls: ['./order-review.component.scss']
})
export class OrderReviewComponent implements OnInit {
    routPath: any = "Add-ons";
    location: Location;
    ShowShippingMethod: boolean = false;
    stepperShow: boolean = false;
    webalias: any;
    queryItemCode: any;
    itemsmemberkit: any;
    itemsmemberkitcustom: any;
    itemsmemberkitBuilder: any;
    BasicKitItem: any;
    LaunchKitItem: any;
    BuilderKitItem: any;
    customertype: any;
    ShippingMethods: any;
    siteType: any;
    IsUltPerformance: any;
    categoryId: any;
    itemsmemberUltLifestyle:any;
    constructor(
        private _toastr: ToastrService,
        private _restService: RestService,
        private _location: Location,
        private _activateRoute: ActivatedRoute,
        public _sharedMethodService: SharedMethodService,
        public _stepperService: StepperService,
        public _enrollSession: EnrollSessionService,
        private _matDialog: MatDialog,
        private router: Router,
        public _calculateRetailOrderService: CalculateRetailOrderService,
        public _auth :AuthService
    ) {
        this._enrollSession.data.customertype = this._enrollSession.data.customertype ? this._enrollSession.data.customertype : 22;
        this.location = this._location;
        if (this._enrollSession.editTransformationPack != 'true' && !this._enrollSession.data.IsLoggedIn) {
            this._enrollSession.data = {};
        }
        // localStorage.clear();
        if (this._enrollSession.data && this._enrollSession.editTransformationPack != 'true' && Object.keys(this._enrollSession.data).length === 0) {
            this._enrollSession.data.IsShowCart = false;
            this._enrollSession.data.page = this.location.path();
            this._enrollSession.data.TotalItemLength = 0;
            this._enrollSession.data.RetailEnroll = {};
            this._enrollSession.data.InitOrder = [];
            this._enrollSession.data.InitialOrdersSubTotal = 0;
            this._enrollSession.data.RetailOrderSummary = {};
            this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
            this._enrollSession.data.RetailOrderSummary.SmartOrders = [];
            this._enrollSession.data.RetailOrderSummary.SmartOrderDetails = {};
            this._enrollSession.data.EligibleForFreeUpgrade = false;
            this._enrollSession.data.RetailEnroll.IsNonprofit = false;
            this._enrollSession.data.FileUploaded = false;
            this._enrollSession.data.RetailEnroll.ShippingMethod = '';
            this._enrollSession.data.usePrimaryCard = null;
            this._enrollSession.data.primaryCreditCardField = {};
            this._enrollSession.data.EnrollmentType = this._enrollSession.data.EnrollmentType || 1;
            this._enrollSession.data.IsUpGrade =
                this._activateRoute.snapshot.queryParamMap.get('customerId') ? this._activateRoute.snapshot.queryParamMap.get('customerId') : false;
            if (this._enrollSession.data.IsUpGrade) {
                localStorage.setItem('customerId', this._enrollSession.data.IsUpGrade);
            }
            this._enrollSession.data.Volume109 = 0;
            this._enrollSession.data.SmartOrderBVTotal = 0;
            this._enrollSession.data.InitialOrdersQVTotal = 0;
            this._enrollSession.data.AddOnOrdersQVTotal = 0;
        }
        this.webalias = this._activateRoute.snapshot.queryParamMap.get('giftcard') || sessionStorage.getItem('giftCardName');
        this.siteType = this._activateRoute.snapshot.queryParamMap.get('site');
        this.queryItemCode = this._activateRoute.snapshot.queryParamMap.get('itemcode')

        if (this.queryItemCode) {
            sessionStorage.setItem("itemcode", this.queryItemCode);
            var snapshot = this._activateRoute.snapshot;
            const params = { ...snapshot.queryParams };
            delete params.itemcode;
            this.router.navigate([], { queryParams: params });
        } else {
            this.queryItemCode = sessionStorage.getItem("itemcode");
        }

        if (this.siteType) {
            sessionStorage.setItem("siteType", this.siteType);
            var snapshot = this._activateRoute.snapshot;
            const params = { ...snapshot.queryParams };
            delete params.site
            this.router.navigate([], { queryParams: params });
        } else {
            this.siteType = sessionStorage.getItem("siteType");
        }

        window.scrollTo(0, 0);
    }
    ngAfterViewInit(): void {

    }

    ngOnInit(): void {
        this._enrollSession.data.RetailOrderSummary = {};
        this._enrollSession.data.RetailOrderSummary.SmartOrders = [];
        if (this.webalias && this.webalias != '' && this.webalias != null && this.webalias != "null") {
            const request = '?WebAlias=' + this.webalias;
            this._restService.GetCustomerSite(request).subscribe((result: any) => {
                if (result.Data != null) {
                    this._enrollSession.data.webAlias = this.webalias;
                    this._enrollSession.data.RetailEnroll.webAlias = result.Data.firstNameField + ' ' + result.Data.lastNameField;
                } else {
                    this._toastr.error('Invalid WebAlias.', 'Error');
                }
            }, error => {
                this._toastr.error('Somthing is wrong Please try again', 'Error');
            });
        }
        if(this.queryItemCode === "11244"){
            let ULTLifestyleProducts = localStorage.getItem('EnrollmentULTLifestyleProducts') ? JSON.parse(localStorage.getItem('EnrollmentULTLifestyleProducts')) : [];
          // this.ULTDarkBerryProducts = localStorage.getItem('EnrollmentULTDarkBerryProducts') ? JSON.parse(localStorage.getItem('EnrollmentULTDarkBerryProducts')) : this.ULTDarkBerryProducts;
          let filterItems = [];
          const productCategories = {
            '11244': ULTLifestyleProducts,
            // '11240': this.ULTLifestyleProducts,
            // '11280': this.ULTDarkBerryProducts
          };
          if(this.queryItemCode == '11244'){
            let productList = productCategories[this.queryItemCode] || [];
            filterItems = productList.filter(item => 
              ["11244", "11254", "11264"].includes(item.ItemCode)
            );
            let singleItem = productList.find(item => item.ItemCode === this.queryItemCode);
            if (singleItem && !filterItems.includes(singleItem)) {
              filterItems.push(singleItem);
            }
            if (filterItems && filterItems.length > 0) {
                this.itemsmemberkitBuilder = filterItems;
                }
            this.popupLoad(filterItems, 1, 1);
          }
        }
          else{
            this.linkBuilderProduct_AddtoCart();
          }
        if (localStorage.getItem("isExistingAutoship") == "true")
        {
            this.ultlifestyleSubscriptionPoup();
        }
        sessionStorage.setItem('onUltLifestyleFlow', 'true');
        
        this._sharedMethodService.isOpen = true;

        this._calculateRetailOrderService.DiscountValue = '';
        this._calculateRetailOrderService.totalRemainingBalance = '';
        this._calculateRetailOrderService.totalRemainingBalanceForSmartOrder = '';
        this._calculateRetailOrderService.isSelected = false;
    }

    popupLoad(item, qty, type): any {
            const dialogRef = this._matDialog.open(OrderSubItemComponent, {
                width: '600px',
                panelClass: ['order-sub-item', 'sub-item-modal'],
                data: {
                    item: item
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                this._sharedMethodService.subOrderItem = false;
            });
    }
    productInfo(item) {
        const dialogRef = this._matDialog.open(productInfoComponent, {
            width: '650px',

            panelClass: 'product-info',
            data: {
                item: item
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == 'Success') {
            }
        });
    }

    private linkBuilderProduct_AddtoCart() {
        if (this.queryItemCode) {
            this.getMemberShipKit().then((items) => {
                _.each(items[0], (item) => {
                    if ((item.ItemCode == this.queryItemCode)) {
                         this.AddToCart(22, item, items[0]);
                    }
                })
            });
        }
    }
    getMemberShipKit(): Promise<any> {
        const promises = [];
        switch (this.queryItemCode) {
            case ('11240'):
                this.categoryId = 504;
                break;
            case ('11241'):
                this.categoryId = 508;
                break;
            case ('11242'):
                this.categoryId = 509;
                break;
            case ('11243'):
                this.categoryId = 510;
                break;
            case ('11248'):
                this.categoryId = 644;
                break;
            case ('11280'):
                this.categoryId = 655;
                break;
            default:
        }
        let filterItemsBuild = [];
        let filterItems = [];
        let allItems = [];
        promises.push(new Promise((resolve, reject) => {
            let request: any = {
                'EnrollmentType': '',
                'CustomerType': 22,
                'WebCategoryId': this.categoryId,
                'isUpgradeCustomer': false
            }
            this._restService.getProducts(request).subscribe((result: any) => {
                if (result) {
                    result.Items.forEach((item) => {
                        if (item.ItemCode === "11240" || item.ItemCode === "11241" || item.ItemCode === "11242" || item.ItemCode === "11243" || item.ItemCode === "11248") {
                            filterItemsBuild.push(item)
                        }
                        allItems.push(item)
                        if (this.queryItemCode == "11240" && (item.ItemCode === "11244" || item.ItemCode === "11254" || item.ItemCode === "11264")) {
                            filterItems.push(item);
                        }
                        if (this.queryItemCode == "11241" && (item.ItemCode === "11245" || item.ItemCode === "11255" || item.ItemCode === "11265")) {
                            filterItems.push(item);
                        }
                        if (this.queryItemCode == "11242" && (item.ItemCode === "11246" || item.ItemCode === "11256" || item.ItemCode === "11266")) {
                            filterItems.push(item);
                        }
                        if (this.queryItemCode == "11243" && (item.ItemCode === "11247" || item.ItemCode === "11257" || item.ItemCode === "11267")) {
                            filterItems.push(item);
                        }
                        if (this.queryItemCode === "11248" && (item.ItemCode === "11249" || item.ItemCode === "11259" || item.ItemCode === "11269")) {
                            filterItems.push(item);
                        }
                        if (this.queryItemCode === "11280" && (item.ItemCode === "11281" || item.ItemCode === "11283" || item.ItemCode === "11285")) {
                            filterItems.push(item);
                        }
                    });
                    resolve(allItems);
                    if (filterItems && filterItems.length > 0) {
                        this.itemsmemberkitcustom = filterItems;
                    }
                }
            },
                error => {
                    console.log(error);
                    reject(allItems);
                    this._toastr.error('Unable to fetch products..', 'Error');
                });
        }));
        const res = Promise.all(promises);
        return res;
    }

    AddToCart(customertype, item, allProducts): any {
        this._enrollSession.data.RetailOrderSummary.SmartOrders = [];
        this._enrollSession.data.customertype = customertype;
        sessionStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
        if (item.ItemCode === "11240") {
            allProducts.forEach(item => {
                if (item.ItemCode == "11250" || item.ItemCode == "11260") {
                    this._sharedMethodService.ContinueRetailOrder(item, 1, 3, (item.ItemCode == "11250" ? "2Month" : "4Month"));
                }
            });
        }
        else if (item.ItemCode === "11241") {
            allProducts.forEach(item => {
                if (item.ItemCode == "11251" || item.ItemCode == "11261") {
                    this._sharedMethodService.ContinueRetailOrder(item, 1, 3, (item.ItemCode == "11251" ? "2Month" : "4Month"));
                }
            });
        }
        else if (item.ItemCode === "11242") {
            allProducts.forEach(item => {
                if (item.ItemCode == "11252" || item.ItemCode == "11262") {
                    this._sharedMethodService.ContinueRetailOrder(item, 1, 3, (item.ItemCode == "11252" ? "2Month" : "4Month"));
                }
            });
        }
        else if (item.ItemCode === "11243") {
            allProducts.forEach(item => {
                if (item.ItemCode == "11253" || item.ItemCode == "11263") {
                    this._sharedMethodService.ContinueRetailOrder(item, 1, 3, (item.ItemCode == "11253" ? "2Month" : "4Month"));
                }
            });
        }
        else if (item.ItemCode === "11248") {
            allProducts.forEach(item => {
                if (item.ItemCode === "11258"|| item.ItemCode === "11268") {
                    this._sharedMethodService.ContinueRetailOrder(item, 1, 3, (item.ItemCode == "11258" ? "2Month" : "4Month"));
                }
            });
        }
        else if (item.ItemCode === "11280") {
            allProducts.forEach(item => {
                if (item.ItemCode === "11282"|| item.ItemCode === "11284") {
                    this._sharedMethodService.ContinueRetailOrder(item, 1, 3, (item.ItemCode == "11282" ? "2Month" : "4Month"));
                }
            });
        }
        this._sharedMethodService.ContinueRetailOrder(item, 1, 1, "");
        this.loadPage(allProducts);
    }

    loadPage(allProducts) {
        let filterItemsBuild = [];
        let selectedItem = this._enrollSession.data.RetailOrderSummary.Enrollpack;
        if (selectedItem) {
            selectedItem.forEach(item => {
                filterItemsBuild.push(item);
            })
            if (filterItemsBuild && filterItemsBuild.length > 0) {
                this.itemsmemberkit = filterItemsBuild;
            }
        }
        this._enrollSession.data.RetailOrderSummary.SmartOrders?.forEach((item) => {

                filterItemsBuild.push(item);
        });

        if (filterItemsBuild && filterItemsBuild.length > 0) {
            this.itemsmemberkitBuilder = filterItemsBuild;
            console.log("itemsmemberkitBuilder",this.itemsmemberkitBuilder)
        }
        if(allProducts && allProducts.length > 0){
            this.itemsmemberUltLifestyle = allProducts;
        }
    }
    ultlifestyleSubscriptionPoup()
    {
        const dialogRef = this._matDialog.open(LifestyleSmartorderSubscriptionComponent, {
            width: '500px',
            maxWidth: '500px',
            height: '356px',
            panelClass: '',
            disableClose: true,
            data: {
                
            }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }
}
