<div class="dialog-content-wrapper" id="SubscriptionText">
  <div style="display:flex;overflow-y: hidden;" class="pop-up-background">
    <div class="pop-up-div">
      <div class="m-0 close-text" (click)="closePopup()">Close
      </div>
      <div class="footer-section-para">
        <p>
            <!-- *Your credit card will be charged for today's order only and securely stored for subscription processing. -->
            Your credit card will be charged for today's order only and securely stored for subscription processing.<br> The Lifestyle Subscription includes:
        </p>
        <div class="lifestylelist">
            <ul>
              <li>Today's order - <b>Make a Commitment Starter Pack with </b> <span style="color: #F36F62;"><b>Free Portable Hand Blender</b></span> </li>
              <li>Month 2 - <b>Stay Consistent & Be Transformed Smart Order Pack</b></li>
              <li>Month 3 - <b>Stay Consistent & Be Transformed Smart Order Pack</b></li>
              <li>Month 4 & Beyond - <b>Maintenance Smart Order Pack "AKA Daily Core 4"</b></li>          
            </ul>
        </div><br>
        <p>
            Your credit card will be charged on the subscription date. Subscriptions may be changed or cancelled anytime.
        </p>
    </div>
    </div>
  </div>
</div>


