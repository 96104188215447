import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShipPopupDialogComponent } from 'app/main/Subscriptions/shipPopUp-dialog/ship-popup-dialog.component';
import { IMAGE_URL } from 'app/constants';

@Component({
  selector: 'app-incentives',
  templateUrl: './incentives.component.html',
  styleUrls: ['./incentives.component.scss']
})
export class IncentivesComponent implements OnInit {

  dialogRef: any;
  showList:boolean = false;
  IMAGE_URL:string = IMAGE_URL;
  constructor(private _matDialog: MatDialog) { }

  ngOnInit(): void {
  }
  smoothscroll(address:any){
    $('html, body').animate({
      scrollTop: $(address).offset().top
    }, 200, function(){
 
      // Add hash (#) to URL when done scrolling (default click behavior)
      window.location.hash = address;
    });
  }
  
  toggleFirstPurchase(){
    if(this.showList == false){
      this.showList = true;
    }
    else{
      this.showList = false;
    }
  }
  OpenAlwaysShipPopup(id){
    this.dialogRef = this._matDialog.open(ShipPopupDialogComponent, {
        width: '429px',
        maxWidth: '429px',
        height: '289px',
        panelClass: '',
        data:id,
        disableClose: true
    });
    this.dialogRef.afterClosed()
        .subscribe(response => {

        });
}
}
