import { NgModule, Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FilterPipeModule } from 'ngx-filter-pipe';
import * as _ from 'underscore';
import { AppComponent } from 'app/app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ToastrModule } from 'ngx-toastr';

import { PagesModule } from 'app/main/pages.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NotTranslatedService } from './nottranslate.service';
import { LoaderService } from './loader.service';
import { LoaderInterceptor } from './loader-interceptor.service';
import { MyLoaderComponent } from './main/my-loader/my-loader.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TodaysOrderComponent } from './main/Todays-order/Todays-order.component';
import { AuthGuard } from './auth.guard';
import { LoginModule } from './LogIn/login.module';
import { CookieService } from 'ngx-cookie-service';
import { ForgotPasswordComponent } from './main/forgot-password/forgot-password.component';
import { LoginComponent } from './LogIn/login.component';
import { forgotPasswordModule } from './main/forgot-password/forgot-password.module';
import { SupportpageComponent } from './shared/footer/supportpage/supportpage.component';
import { ShippingpolicyComponent } from './shared/footer/shippingpolicy/shippingpolicy.component';
import { PrivacypolicyComponent } from './shared/footer/privacypolicy/privacypolicy.component';
import { IncentivesComponent } from './shared/footer/incentives/incentives.component';
import { UltpackComponent } from './main/ultpack/ultpack.component';
import { UltlifestyleComponent } from './main/ultlifestyle/ultlifestyle.component';
import { OrderReviewComponent } from './main/order-review/order-review.component';
import { productInfoComponent } from './shared/Product-info/Product-info.component';
import { HomePageComponent } from './main/home/homepage.component';
import { FAQComponent } from './main/faq/faq.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ResetShopifyPasswordComponent } from './main/reset-shopify-password/reset-shopify-password.component';
import { LocationStrategy } from '@angular/common';
import { PathPreserveQueryLocationStrategy } from './shared/preserve-query-params';
import { NewsLetterSignupComponent } from './main/newslettersignup/newslettersignup.component';
import { FAQChatBoxComponent } from './main/faq-chatbox/faq-chatbox.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { ReturnpolicyComponent } from './shared/footer/returnpolicy/returnpolicy.component';
import { TransformationComponent } from './main/transformation/transformation.component';
const appRoutes: Routes = [
  {
    path: 'Login',
    loadChildren: () => import('./LogIn/login.module').then(a => a.LoginModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./LogIn/login.module').then(a => a.LoginModule),
  },
  {
    path: '',
    redirectTo: '/homepage',
    pathMatch: 'full',
  },
  {
    path: 'AllProducts',
    component: TodaysOrderComponent,
    pathMatch: 'full',
  },
  {
    path: 'cantepack',
    component: TodaysOrderComponent,
    pathMatch: 'full',
  },
  {
    path: 'AllProducts/:id',
    component: productInfoComponent,
    pathMatch: 'full'
  },
  {
    path: 'products/:id',
    component: productInfoComponent,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/homepage',
    pathMatch: 'full',
  },
  {
    path: 'Home',
    component: HomePageComponent,
    pathMatch: 'full',
  },
  {
    path: 'Login',
    component: LoginComponent,
    pathMatch: 'full',
  },
    {
    path: 'forgotPassword',
    component: ForgotPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'documents/privacy',
    component: PrivacypolicyComponent,
    pathMatch: 'full',
  },
  {
    path: 'documents/return',
    component: ReturnpolicyComponent,
    pathMatch:'full'
  },
  {
    path: 'documents/shipping',
    component: ShippingpolicyComponent,
    pathMatch: 'full',
  },
  {
    path: 'documents/support',
    component: SupportpageComponent,
    pathMatch: 'full',
  },
  {
    path: 'documents/incentives',
    component: IncentivesComponent,
    pathMatch: 'full',
  },
  {
    path: 'Ultpack',
    component: UltpackComponent,
    pathMatch: 'full',
  },
  {
    path: 'Ultlifestyle',
    component: TransformationComponent,
    pathMatch: 'full',
  },
  {
    path: 'OrderReview',
    component: OrderReviewComponent,
    pathMatch: 'full',
  },
  {
    path: 'homepage',
    component: HomePageComponent,
    pathMatch: 'full',
  },
  {
    path: 'ResetShopifyPassword',
    component: ResetShopifyPasswordComponent,
    pathMatch: 'full',
    },
    {
        path: 'FAQ',
        component: FAQComponent,
        pathMatch: 'full',
    },
    {
        path: 'faq',
        component: FAQComponent,
        pathMatch: 'full',
    },
    {
      path: 'getnews',
      component: NewsLetterSignupComponent,
      pathMatch: 'full',
    },
    // {
    //   path: 'Transformation',
    //   component: TransformationComponent,
    //   pathMatch: 'full',
    // }
];
@Injectable()
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    if (params.interpolateParams) {
      return params.interpolateParams['Default'] || params.key;
    }
    return params.key;
  }

  constructor(private nts: NotTranslatedService) { }
}
@NgModule({
  declarations: [
    AppComponent,
    MyLoaderComponent,
    FAQChatBoxComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    RouterModule.forRoot(appRoutes, {
      enableTracing: false
    }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
        deps: [NotTranslatedService]
      }
    }),
    // Material moment date module
    MatMomentDateModule,
    // Material
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatDialogModule,
    // App modules
    PagesModule,
    // LayoutModule,
    FilterPipeModule,
    MatSidenavModule,
    LoginModule,
    MatTooltipModule,
    forgotPasswordModule,
    ToastrModule.forRoot(),
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatChipsModule
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    AuthGuard,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    CookieService,
    { provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy }
  ]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
